let apiBaseUrl = "";
let appId = "";

if (window.location.hostname.indexOf("feedback.luxottica") >= 0) {
  apiBaseUrl = "https://productfeedback.luxottica.com/server/api";
  appId = "d3cec26b-3604-4115-a330-8d6237e8d66e";
} else if (window.location.hostname.indexOf("survwap01") >= 0) {
  apiBaseUrl = "https://elncsurvwap01.azurewebsites.net/server/api";
  appId = "d3cec26b-3604-4115-a330-8d6237e8d66e";
} else if (window.location.hostname.indexOf("salesmarket") >= 0) {
  apiBaseUrl =
    "https://salesmarketprojectv3-local.azurewebsites.net/server/api";
  appId = "658bf4eb-94e5-4335-8028-985f16178702";
} else {
  apiBaseUrl = "https://localhost:7036/api";
  appId = "658bf4eb-94e5-4335-8028-985f16178702";
}

export const API_BASE_URL = apiBaseUrl;
export const AAD_CLIENT_ID = appId;
