import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { appConstants } from "src/appConstants";
import { ConfirmDialog } from "src/core/components/ConfirmDialog";
import { OnePageSurvey } from "src/core/components/OnePageSurvey";
import Page from "src/core/components/Page/Page";
import PageContent from "src/core/components/PageContent";
import { PageHeader } from "src/core/components/PageHeader";
import Skeleton from "src/core/components/Skeleton";
import { useAuth } from "src/core/hooks/useAuth";
import { surveyService } from "src/core/services/surveyService";
import {
  defaultDarkDialogButtonStyles,
  primaryDarkDialogButtonStyles,
} from "src/core/styles/common.styles";
import { Survey } from "src/types/common";

export const AppSurvey = () => {
  const [loading, setLoading] = useState(true);
  const [showBackConfirm, setShowBackConfirm] = useState(false);
  const [survey, setSurvey] = useState<Survey>();

  const { accessToken, success, authLoaded } = useAuth();
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (authLoaded) {
        if (success) {
          const response = await surveyService.fetchSurvey(
            accessToken,
            appConstants()?.appSurveyId ?? 0,
            i18n.resolvedLanguage
          );
          setSurvey(response);
          setLoading(false);
        } else {
          navigate(`/`);
        }
      }
    })();
  }, [accessToken, i18n.resolvedLanguage, authLoaded, navigate, success]);

  return (
    <Page>
      <Skeleton loading={loading}>
        <ConfirmDialog
          showConfirm={showBackConfirm}
          hideConfirm={() => {
            setShowBackConfirm(false);
          }}
          onSubmit={() => {
            navigate(`/`);
          }}
          title={t("modals.confirmGoBack")}
          subTitle={t("modals.confirmGoBackSubTitle")}
          cancelButton={t("resources.58")}
          continueButton={t("modals.continue")}
          submitButtonStyles={primaryDarkDialogButtonStyles}
          cancelButtonStyles={defaultDarkDialogButtonStyles}
        />
        <PageHeader
          header={t(`resources.42`)}
          onBackClick={() => {
            setShowBackConfirm(true);
          }}
        />
        <PageContent paddingTop={90}>
          {survey && (
            <OnePageSurvey
              key={`app-survey`}
              survey={survey}
              onSuccess={() => {
                navigate(`/app-survey-success`);
              }}
            />
          )}
        </PageContent>
      </Skeleton>
    </Page>
  );
};
