import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { ConfirmDialog } from "src/core/components/ConfirmDialog";
import Page from "src/core/components/Page/Page";
import PageContent from "src/core/components/PageContent";
import { PageHeader } from "src/core/components/PageHeader";
import { PaginatedSurvey } from "src/core/components/PaginatedSurvey";
import Skeleton from "src/core/components/Skeleton";
import { useAuth } from "src/core/hooks/useAuth";
import { surveyService } from "src/core/services/surveyService";
import {
  defaultDarkDialogButtonStyles,
  primaryDarkDialogButtonStyles,
} from "src/core/styles/common.styles";
import { getSurveyPageTitle } from "src/core/utils/survey";
import { Header } from "src/pages/ModelSurvey/components/Header";
import { mainAppState } from "src/recoil/atoms";
import { Survey } from "src/types/common";

export const ModelSurvey = () => {
  const appState = useRecoilValue(mainAppState);

  const [loading, setLoading] = useState(true);
  const [showBackConfirm, setShowBackConfirm] = useState(false);

  const [currentSurveyPage, setCurrentSurveyPage] = useState(0);

  const [modelSurvey, setModelSurvey] = useState<Survey>();

  const { accessToken, success } = useAuth();
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (
        appState.selectedRegion &&
        appState.selectedBrand &&
        appState.selectedModel
      ) {
        if (success) {
          const response = await surveyService.getModelSurvey(
            accessToken,
            appState.selectedModel.number,
            i18n.resolvedLanguage
          );

          setModelSurvey(response);
          setLoading(false);
        }
      } else {
        navigate(`/`);
      }
    })();
  }, [
    accessToken,
    appState.selectedBrand,
    appState.selectedModel,
    appState.selectedRegion,
    i18n.resolvedLanguage,
    navigate,
    success,
  ]);

  return (
    <Page>
      <Skeleton loading={loading}>
        <ConfirmDialog
          showConfirm={showBackConfirm}
          hideConfirm={() => {
            setShowBackConfirm(false);
          }}
          onSubmit={() => {
            navigate(`/brand-models`);
          }}
          title={t("modals.confirmGoBack")}
          subTitle={t("modals.confirmGoBackSubTitle")}
          cancelButton={t("resources.58")}
          continueButton={t("modals.continue")}
          submitButtonStyles={primaryDarkDialogButtonStyles}
          cancelButtonStyles={defaultDarkDialogButtonStyles}
        />
        <PageHeader
          header={getSurveyPageTitle(
            appState.selectedBrand?.name,
            t(`markets.${appState.selectedMarket?.name}`)
          )}
          customSubHeader={<Header model={appState.selectedModel} />}
          onBackClick={
            currentSurveyPage === 0
              ? () => {
                  setShowBackConfirm(true);
                }
              : undefined
          }
        />
        <PageContent paddingTop={110}>
          {modelSurvey && (
            <PaginatedSurvey
              key={`model-survey`}
              survey={modelSurvey}
              marketId={appState.selectedMarket?.id || 0}
              brandCode={appState.selectedBrand?.code}
              modelNumber={appState.selectedModel?.number}
              collection={appState.selectedModel?.collection}
              onSuccess={() => {
                navigate(`/model-survey-success`);
              }}
              shareCurrentPageNumber={setCurrentSurveyPage}
            />
          )}
        </PageContent>
      </Skeleton>
    </Page>
  );
};
