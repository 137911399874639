import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Page from "src/core/components/Page/Page";
import PageContent from "src/core/components/PageContent";
import { PageHeader } from "src/core/components/PageHeader";
import Skeleton from "src/core/components/Skeleton";
import { SurveyResponseRender } from "src/core/components/SurveyResponseRender/SurveyResponseRender";
import { useAuth } from "src/core/hooks/useAuth";
import { surveyResponseService } from "src/core/services/surveyResponseService";
import { getSurveyPageTitle } from "src/core/utils/survey";
import { Header } from "src/pages/ModelSurvey/components/Header";
import { mainAppState } from "src/recoil/atoms";
import { SurveyResponse } from "src/types/common";

export const ModelSurveyResponseReview = () => {
  const appState = useRecoilValue(mainAppState);

  const [loading, setLoading] = useState(true);

  const [surveyResponse, setSurveyResponse] = useState<SurveyResponse>();

  const { accessToken, success } = useAuth();
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (
        appState.selectedRegion &&
        appState.selectedMarket &&
        appState.selectedBrand &&
        appState.selectedModel
      ) {
        if (success) {
          const response = await surveyResponseService.fetchModelSurveyResponse(
            accessToken,
            appState.selectedModel.number,
            appState.selectedBrand,
            appState.selectedMarket.id,
            i18n.resolvedLanguage
          );

          setSurveyResponse(response);
          setLoading(false);
        }
      } else {
        navigate(`/`);
      }
    })();
  }, [
    accessToken,
    appState.selectedBrand,
    appState.selectedMarket,
    appState.selectedModel,
    appState.selectedRegion,
    i18n.resolvedLanguage,
    navigate,
    success,
  ]);

  return (
    <Page>
      <Skeleton loading={loading}>
        <PageHeader
          header={getSurveyPageTitle(
            appState.selectedBrand?.name,
            t(`markets.${appState.selectedMarket?.name}`)
          )}
          customSubHeader={<Header model={appState.selectedModel} />}
          onBackClick={() => {
            navigate(`/brand-models`);
          }}
        />
        <PageContent>
          {surveyResponse && (
            <SurveyResponseRender surveyResponse={surveyResponse} />
          )}
        </PageContent>
      </Skeleton>
    </Page>
  );
};
