import { useMsal } from "@azure/msal-react";
import { Image, PrimaryButton, Stack, Text } from "@fluentui/react";
import { Dropdown, IDropdownOption } from "@fluentui/react/lib/Dropdown";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { loadAppConstants } from "src/appConstants";
import logo from "src/assets/logo_main.png";
import Page from "src/core/components/Page/Page";
import Skeleton from "src/core/components/Skeleton";
import UserAvatar from "src/core/components/UserAvatar";
import { useAuth } from "src/core/hooks/useAuth";
import { managementService } from "src/core/services/managementService";
import { userService } from "src/core/services/userService";
import { primaryDarkButtonStyles } from "src/core/styles/common.styles";
import {
  currentUserState,
  mainAppState,
  marketsState,
  regionsState,
} from "src/recoil/atoms";
import { Market, Region } from "src/types/common";
import {
  dropdownStyles,
  headerLogoStyle,
  rateThisAppStyle,
  signOutStyle,
} from "./Welcome.styles";

export const Welcome = () => {
  const [appState, setAppState] = useRecoilState(mainAppState);
  const [regionsList, setRegionsList] = useRecoilState(regionsState);
  const [marketsList, setMarketsList] = useRecoilState(marketsState);
  const [currentUser, setCurrentUser] = useRecoilState(currentUserState);

  const [loading, setLoading] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [fetchingMarkets, setFetchingMarkets] = useState(false);

  const [regionsDropdown, setRegionsDropdown] = useState<IDropdownOption[]>([]);
  const [marketsDropdown, setMarketsDropdown] = useState<IDropdownOption[]>([]);

  const { accessToken, success, graphAccessToken } = useAuth();
  const { instance } = useMsal();

  const { t, i18n } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    document.documentElement.setAttribute("lang", i18n.resolvedLanguage);
  }, [i18n.resolvedLanguage]);

  useEffect(() => {
    (async () => {
      if (success) {
        await loadAppConstants(accessToken);
      }
    })();
  }, [accessToken, success]);

  useEffect(() => {
    (async () => {
      if (success) {
        if (!currentUser) {
          const userResponse = await userService.fetchUser(accessToken);
          setCurrentUser(userResponse);
        }
      }
    })();
  }, [accessToken, currentUser, setCurrentUser, success]);

  useEffect(() => {
    (async () => {
      if (currentUser && success) {
        if (regionsList.length === 0) {
          const regionsResponse = await managementService.fetchRegions(
            accessToken
          );

          setRegionsList(regionsResponse);
        }

        if (regionsList && regionsList.length > 0) {
          let values: IDropdownOption[] = [];
          regionsList.forEach((region) =>
            values.push({
              key: region.id,
              text: t(`regions.${region.name}`),
              data: region,
            })
          );

          setRegionsDropdown(values);
        }

        setLoading(false);
      }
    })();
  }, [accessToken, currentUser, regionsList, setRegionsList, success, t]);

  useEffect(() => {
    (async () => {
      if (currentUser && success && appState.selectedRegion) {
        setFetchingMarkets(true);

        let marketsResponse: Market[] = [];

        if (marketsList?.regionId !== appState.selectedRegion.id) {
          marketsResponse = await managementService.fetchRegionMarkets(
            appState.selectedRegion.id,
            accessToken
          );

          setMarketsList({
            regionId: appState.selectedRegion.id,
            markets: marketsResponse,
          });
        } else {
          marketsResponse = marketsList.markets;
        }

        if (marketsResponse && marketsResponse.length > 0) {
          let values: IDropdownOption[] = [];
          marketsResponse.forEach((market) =>
            values.push({
              key: market.id,
              text: t(`markets.${market.name}`),
              data: market,
            })
          );

          setMarketsDropdown(values);
        }

        setFetchingMarkets(false);
      }
    })();
  }, [
    accessToken,
    appState.selectedRegion,
    currentUser,
    marketsList,
    setMarketsList,
    success,
    t,
  ]);

  // preselect user region
  useEffect(() => {
    (async () => {
      if (currentUser && success) {
        if (currentUser.regionId) {
          if (!appState.selectedRegion) {
            const regions = regionsList.filter(
              (reg: Region) => reg.id === currentUser.regionId
            );

            let userRegion: Region | undefined = undefined;

            if (regions.length > 0) {
              userRegion = regions[0];
            }

            if (userRegion) {
              setAppState({
                selectedRegion: userRegion,
              });
            }
          }
        }
      }
    })();
  }, [appState.selectedRegion, currentUser, regionsList, setAppState, success]);

  // preselect user market
  useEffect(() => {
    (async () => {
      if (!isDirty && currentUser && success) {
        if (currentUser.marketId) {
          if (!appState.selectedMarket) {
            let userMarket: Market | undefined = undefined;

            const markets = marketsDropdown.filter(
              (m: IDropdownOption) =>
                m.data.id === currentUser.marketId &&
                m.data.regionId === currentUser.regionId
            );

            if (markets.length > 0) {
              userMarket = markets[0].data;
            }

            if (userMarket) {
              setAppState({ ...appState, selectedMarket: userMarket });
            }
          }
        }
      }
    })();
  }, [appState, currentUser, isDirty, marketsDropdown, setAppState, success]);

  return (
    <Page>
      <Skeleton loading={loading}>
        <Stack horizontalAlign="center">
          <Image style={headerLogoStyle} src={logo} />
          <h1 style={{ fontWeight: "600", textAlign: "center" }}>
            {t("resources.1")}
          </h1>
          <Stack horizontalAlign="center">
            <h2 style={{ fontWeight: "normal" }}>
              {t("resources.2")} {instance.getActiveAccount()?.name}
            </h2>
            <UserAvatar accessToken={graphAccessToken} />
          </Stack>
        </Stack>
        <Stack
          tokens={{ childrenGap: "20" }}
          horizontalAlign="center"
          style={{ marginTop: "30px" }}
        >
          <Dropdown
            key="region-d"
            label={t("resources.3")}
            placeholder={"---"}
            options={regionsDropdown}
            styles={dropdownStyles}
            selectedKey={appState.selectedRegion?.id ?? null}
            onChange={(_e, option) => {
              setAppState({
                selectedRegion: option?.data,
                selectedMarket: undefined,
              });
              if (!isDirty) {
                setIsDirty(true);
              }
            }}
          />
          <Dropdown
            disabled={!appState.selectedRegion || fetchingMarkets}
            key="market-d"
            label={t("resources.4")}
            placeholder={"---"}
            options={marketsDropdown}
            styles={dropdownStyles}
            selectedKey={appState.selectedMarket?.id ?? null}
            onChange={(_e, option) => {
              setAppState({ ...appState, selectedMarket: option?.data });
              userService.updateUser(accessToken, {
                regionId: appState.selectedRegion?.id,
                marketId: option?.data.id,
              });
              if (!isDirty) {
                setIsDirty(true);
              }
            }}
          />
        </Stack>
        <Stack
          tokens={{ childrenGap: "20" }}
          horizontalAlign="center"
          style={{ marginTop: "40px" }}
        >
          <PrimaryButton
            text={t("resources.5")}
            styles={primaryDarkButtonStyles}
            disabled={!appState.selectedRegion || !appState.selectedMarket}
            onClick={() => {
              navigate(`/model-brands`);
            }}
          />
          <PrimaryButton
            text={t("resources.6")}
            styles={primaryDarkButtonStyles}
            disabled={!appState.selectedRegion || !appState.selectedMarket}
            onClick={() => {
              navigate(`/overall-brands`);
            }}
          />
          <Text
            style={rateThisAppStyle}
            onClick={() => {
              navigate(`/app-survey`);
            }}
          >
            {t("resources.7")}
          </Text>
        </Stack>
        <Stack horizontalAlign="center">
          <Text
            style={signOutStyle}
            onClick={() => {
              instance.logout();
            }}
          >
            {t("buttons.signOut")}
          </Text>
        </Stack>
      </Skeleton>
    </Page>
  );
};
