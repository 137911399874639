import { PrimaryButton, Text } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Page from "src/core/components/Page/Page";
import PageContent from "src/core/components/PageContent";
import { PageHeader } from "src/core/components/PageHeader";
import Skeleton from "src/core/components/Skeleton";
import { useAuth } from "src/core/hooks/useAuth";
import { primaryDarkButtonStyles } from "src/core/styles/common.styles";

export const AppSurveySuccess = () => {
  const [loading, setLoading] = useState(true);

  const { success, authLoaded } = useAuth();
  const { t } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (authLoaded) {
        if (success) {
          setLoading(false);
        } else {
          navigate(`/`);
        }
      }
    })();
  }, [authLoaded, navigate, success]);

  return (
    <Page>
      <Skeleton loading={loading}>
        <PageHeader
          header={""}
          onBackClick={() => {
            navigate(`/`);
          }}
        />
        <PageContent paddingTop={110}>
          <Text
            as="h2"
            variant="xLarge"
            style={{ marginBottom: 10, textAlign: "center" }}
          >
            {t(`resources.66`)}
          </Text>
          <PrimaryButton
            styles={primaryDarkButtonStyles}
            onClick={() => {
              navigate(`/`);
            }}
          >
            {t(`resources.65`)}
          </PrimaryButton>
        </PageContent>
      </Skeleton>
    </Page>
  );
};
