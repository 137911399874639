import { AuthenticationResult, InteractionStatus } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { loginRedirectRequest } from "src/authConfig";

export function useAuth(forceRefresh: boolean = false) {
  const [authLoaded, setAuthLoaded] = useState(false);
  const [success, setSuccess] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [graphAccessToken, setGraphAccessToken] = useState("");

  const { instance, inProgress } = useMsal();

  useEffect(() => {
    (async () => {
      const request = {
        ...loginRedirectRequest,
      };

      if (inProgress === InteractionStatus.None) {
        // Silently acquires an access token which is then attached to a request
        instance
          .acquireTokenSilent({ ...request, forceRefresh: forceRefresh })
          .then((response: AuthenticationResult) => {
            setAccessToken(response.idToken);
            setGraphAccessToken(response.accessToken);
            setSuccess(true);
            setAuthLoaded(true);
          })
          .catch((e) => {
            instance.loginRedirect(loginRedirectRequest);
          });
      }
    })();
  });

  return {
    accessToken: accessToken,
    graphAccessToken: graphAccessToken,
    success: success,
    authLoaded: authLoaded,
  };
}
