import {
  DefaultButton,
  FocusTrapZone,
  IButtonStyles,
  Layer,
  mergeStyleSets,
  Overlay,
  Popup,
  PrimaryButton,
  Spinner,
  Stack,
  Text,
} from "@fluentui/react";
import { AlertSolidIcon } from "@fluentui/react-icons-mdl2";
import * as React from "react";
import { useEffect } from "react";

const popupStyles = mergeStyleSets({
  root: {
    background: "rgba(0, 0, 0, 0.2)",
    bottom: "0",
    left: "0",
    position: "fixed",
    right: "0",
    top: "0",
  },
  content: {
    background: "white",
    left: "50%",
    width: "90%",
    maxWidth: "400px",
    padding: "0 2em 2em",
    position: "absolute",
    top: "25%",
    transform: "translate(-50%, -50%)",
  },
});

export interface Props {
  showConfirm: boolean;
  hideConfirm: () => void;
  onSubmit: () => void;
  title: string;
  subTitle: string;
  cancelButton: string;
  continueButton: string;
  error?: boolean;
  errorMessage?: string;
  submitButtonStyles?: IButtonStyles;
  cancelButtonStyles?: IButtonStyles;
}

export const ConfirmDialog: React.FunctionComponent<Props> = (props: Props) => {
  const {
    showConfirm,
    hideConfirm,
    onSubmit,
    title,
    subTitle,
    cancelButton,
    continueButton,
    error,
    errorMessage,
    submitButtonStyles,
    cancelButtonStyles,
  } = props;

  const [inProgress, setInProgress] = React.useState(false);

  useEffect(() => {
    if (error) {
      setInProgress(false);
    }
  }, [error]);

  return (
    <React.Fragment>
      {showConfirm && (
        <Layer>
          <Popup
            className={popupStyles.root}
            role="dialog"
            aria-modal="true"
            onDismiss={() => {
              hideConfirm();
            }}
            enableAriaHiddenSiblings={true}
          >
            <Overlay
              onClick={() => {
                hideConfirm();
              }}
            />
            <FocusTrapZone>
              <Stack
                horizontalAlign="center"
                role="document"
                className={popupStyles.content}
              >
                <AlertSolidIcon
                  style={{
                    fontSize: 30,
                    marginTop: 20,
                    marginBottom: 10,
                    textAlign: "center",
                  }}
                />
                <Text
                  as="h2"
                  variant="xLarge"
                  style={{ marginBottom: 8, textAlign: "center" }}
                >
                  {title}
                </Text>
                <Text style={{ textAlign: "justify" }} variant="mediumPlus">
                  {subTitle}
                </Text>
                <Stack horizontal style={{ marginTop: 20, width: "100%" }}>
                  <Stack horizontalAlign="start" style={{ width: "50%" }}>
                    <DefaultButton
                      styles={cancelButtonStyles}
                      width={100}
                      onClick={() => {
                        hideConfirm();
                      }}
                      style={{ width: cancelButtonStyles ? undefined : 100 }}
                      disabled={inProgress}
                    >
                      {cancelButton}
                    </DefaultButton>
                  </Stack>
                  <Stack horizontalAlign="end" style={{ width: "50%" }}>
                    <PrimaryButton
                      styles={submitButtonStyles}
                      style={{ width: submitButtonStyles ? undefined : 120 }}
                      onClick={() => {
                        setInProgress(true);
                        onSubmit();
                      }}
                    >
                      {inProgress ? <Spinner /> : continueButton}
                    </PrimaryButton>
                    {error && (
                      <Text
                        style={{
                          marginTop: 5,
                          width: 120,
                          color: "red",
                          textAlign: "center",
                        }}
                      >
                        {errorMessage}
                      </Text>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </FocusTrapZone>
          </Popup>
        </Layer>
      )}
    </React.Fragment>
  );
};
