import { PrimaryButton, Text } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import Page from "src/core/components/Page/Page";
import PageContent from "src/core/components/PageContent";
import { PageHeader } from "src/core/components/PageHeader";
import Skeleton from "src/core/components/Skeleton";
import { useAuth } from "src/core/hooks/useAuth";
import { primaryDarkButtonStyles } from "src/core/styles/common.styles";
import { getSurveyPageTitle } from "src/core/utils/survey";
import { Header } from "src/pages/ModelSurvey/components/Header";
import { mainAppState } from "src/recoil/atoms";

export const ModelSurveySuccess = () => {
  const appState = useRecoilValue(mainAppState);

  const [loading, setLoading] = useState(true);

  const { accessToken, success } = useAuth();
  const { t } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (appState.selectedRegion && appState.selectedBrand) {
        if (success) {
          setLoading(false);
        }
      } else {
        navigate(`/`);
      }
    })();
  }, [
    accessToken,
    appState.selectedBrand,
    appState.selectedRegion,
    navigate,
    success,
  ]);

  return (
    <Page>
      <Skeleton loading={loading}>
        <PageHeader
          header={getSurveyPageTitle(
            appState.selectedBrand?.name,
            t(`markets.${appState.selectedMarket?.name}`)
          )}
          customSubHeader={<Header model={appState.selectedModel} />}
          onBackClick={() => {
            navigate(`/brand-models`);
          }}
        />
        <PageContent paddingTop={110}>
          <Text
            as="h2"
            variant="xLarge"
            style={{ marginBottom: 20, textAlign: "center" }}
          >
            {t(`resources.60`)}
          </Text>
          <PrimaryButton
            styles={primaryDarkButtonStyles}
            onClick={() => {
              navigate(`/brand-models`);
            }}
          >
            {t("resources.61")}
          </PrimaryButton>
        </PageContent>
      </Skeleton>
    </Page>
  );
};
