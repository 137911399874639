import { Stack, Text } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { appConstants } from "src/appConstants";
import { SurveyPageFooter } from "src/core/components/SurveyQuestions/components/SurveyPageFooter";
import { SurveyQuestionRender } from "src/core/components/SurveyQuestions/components/SurveyQuestionRender";
import {
  SurveyQuestion,
  SurveyQuestionGroup,
  SurveyQuestionOption,
} from "src/types/common";

export interface Props {
  surveyQuestionGroups: SurveyQuestionGroup[];
  onSave: (
    selectedQuestions: SurveyQuestion[],
    selectedOptions: SurveyQuestionOption[],
    removedSurveyQuestions?: SurveyQuestion[]
  ) => void;
  submitButtonText: string;
  secondaryButtonText?: string;
  onClickSecondaryButton?: () => void;
  preselectedSurveyQuestionOptions?: SurveyQuestionOption[];
  preselectedSurveyQuestions?: SurveyQuestion[];
}

export const SurveyQuestions = (props: Props) => {
  const {
    submitButtonText,
    surveyQuestionGroups,
    onSave,
    secondaryButtonText,
    onClickSecondaryButton,
    preselectedSurveyQuestionOptions,
    preselectedSurveyQuestions,
  } = props;

  const [selectedOptions, setSelectedOptions] = useState<
    SurveyQuestionOption[]
  >([]);
  const [selectedQuestions, setSelectedQuestions] = useState<SurveyQuestion[]>(
    []
  );

  const [removedQuestions, setRemovedQuestions] = useState<SurveyQuestion[]>(
    []
  );

  const [validationError, setValidationError] = useState(false);

  const [textRexExp, setTextRegExp] = useState<RegExp>();

  useEffect(() => {
    const validationRegex = appConstants()?.freeTextValidationRegex;

    if (validationRegex) {
      var regExp = new RegExp(validationRegex);

      setTextRegExp(regExp);
    }
  }, []);

  const onQuestionChange = (
    add: boolean,
    question: SurveyQuestion,
    option?: SurveyQuestionOption,
    removeAllExistingQuestionOptions?: boolean
  ) => {
    if (option) {
      // question with option change
      if (add) {
        if (removeAllExistingQuestionOptions) {
          let filteredList = selectedOptions.filter(function (
            opt: SurveyQuestionOption
          ) {
            return opt.surveyQuestionId !== question.id;
          });

          filteredList.push(option);

          setSelectedOptions(filteredList);
        } else {
          let filteredList = selectedOptions.filter(function (
            opt: SurveyQuestionOption
          ) {
            return opt.id !== option.id;
          });

          setSelectedOptions([...filteredList, option]);
        }
      } else {
        if (removeAllExistingQuestionOptions) {
          let filteredList = selectedOptions.filter(function (
            opt: SurveyQuestionOption
          ) {
            return opt.surveyQuestionId !== question.id;
          });

          setSelectedOptions(filteredList);
        } else {
          let filteredList = selectedOptions.filter(function (
            opt: SurveyQuestionOption
          ) {
            return opt.id !== option.id;
          });

          setSelectedOptions(filteredList);
        }
      }
    } else {
      // questions without options
      let filteredList = selectedQuestions.filter(function (q: SurveyQuestion) {
        return q.id !== question.id;
      });

      if (add) {
        filteredList.push(question);
      } else {
        setRemovedQuestions([...removedQuestions, question]);
      }

      setSelectedQuestions(filteredList);
    }
  };

  const isFreeTextValid = (text: string): boolean => {
    if (text && textRexExp) {
      return !textRexExp.test(text);
    }

    return true;
  };

  const onSubmit = () => {
    if (onValidate()) {
      onSave(selectedQuestions, selectedOptions, removedQuestions);
    }
  };

  const onValidate = (): boolean => {
    let noErrors = true;
    setValidationError(false);

    surveyQuestionGroups.forEach((group: SurveyQuestionGroup) => {
      group.surveyQuestions.forEach((question: SurveyQuestion) => {
        if (noErrors) {
          if (question.isRequired) {
            if (question.type === "FreeText") {
              const anyQuestionIndex = selectedQuestions.findIndex(function (
                q: SurveyQuestion
              ) {
                return (
                  q.id === question.id &&
                  q.value?.trim() !== "" &&
                  isFreeTextValid((q.value ?? "").trim())
                );
              });

              if (anyQuestionIndex < 0) {
                noErrors = false;
                setValidationError(true);
              }
            } else {
              const anyOptionIndex = selectedOptions.findIndex(function (
                opt: SurveyQuestionOption
              ) {
                return opt.surveyQuestionId === question.id;
              });

              if (anyOptionIndex < 0) {
                noErrors = false;
                setValidationError(true);
              }

              const anyFreetextOptionIndex = selectedOptions.findIndex(
                function (opt: SurveyQuestionOption) {
                  return (
                    opt.type === "FreeText" &&
                    ((opt.customValue?.trim() ?? "") === "" ||
                      !isFreeTextValid((opt.customValue ?? "").trim()))
                  );
                }
              );

              if (anyFreetextOptionIndex >= 0) {
                noErrors = false;
                setValidationError(true);
              }
            }
          } else {
            if (question.type === "FreeText" && selectedQuestions.length > 0) {
              const anyQuestionIndex = selectedQuestions.findIndex(function (
                q: SurveyQuestion
              ) {
                return (
                  q.id === question.id &&
                  !isFreeTextValid((q.value ?? "").trim())
                );
              });

              if (anyQuestionIndex > -1) {
                noErrors = false;
                setValidationError(true);
              }
            }
          }
        }

        // image type questions are ussually not required but it requires to add a comment if image is attached
        if (question.type === "Image") {
          const anyQuestionIndex = selectedQuestions.findIndex(function (
            q: SurveyQuestion
          ) {
            return (
              q.id === question.id &&
              q.type === "Image" &&
              (q.attachments?.length === 0 ||
                q.value?.trim() === "" ||
                !isFreeTextValid((q.value ?? "").trim()))
            );
          });

          if (anyQuestionIndex >= 0) {
            noErrors = false;
            setValidationError(true);
          }
        }
      });
    });

    setValidationError(!noErrors);

    return noErrors;
  };

  useEffect(() => {
    if (
      preselectedSurveyQuestionOptions &&
      preselectedSurveyQuestionOptions.length > 0
    ) {
      setSelectedOptions(preselectedSurveyQuestionOptions);
    }
  }, [preselectedSurveyQuestionOptions]);

  return (
    <Stack tokens={{ childrenGap: "20" }} style={{ maxWidth: 400 }}>
      {surveyQuestionGroups && (
        <React.Fragment>
          {surveyQuestionGroups?.map((group: SurveyQuestionGroup, index) => {
            return (
              <Stack tokens={{ childrenGap: "21" }} key={`qg-${index}`}>
                {group.displayTitle && group.displayTitle !== "" && (
                  <Stack>
                    <Text
                      variant={
                        group.displayDescription ? "mediumPlus" : "xLarge"
                      }
                      style={{
                        fontWeight: group.displayDescription ? 500 : 600,
                        textAlign: "justify",
                        lineHeight: 1.1,
                      }}
                    >
                      {group.displayTitle}
                    </Text>
                    {group.displayDescription &&
                      group.displayDescription !== "" && (
                        <Text
                          variant={"mediumPlus"}
                          style={{
                            textAlign: "justify",
                            marginTop: 10,
                          }}
                        >
                          {group.displayDescription}
                        </Text>
                      )}
                  </Stack>
                )}

                {group.surveyQuestions.map(
                  (question: SurveyQuestion, index) => {
                    return (
                      <SurveyQuestionRender
                        key={`sq-${index}`}
                        surveyQuestion={question}
                        onQuestionChange={onQuestionChange}
                        preselectedSurveyQuestionOptions={
                          preselectedSurveyQuestionOptions
                        }
                        preselectedSurveyQuestions={preselectedSurveyQuestions}
                      />
                    );
                  }
                )}
              </Stack>
            );
          })}

          <SurveyPageFooter
            validationError={validationError}
            onSubmit={onSubmit}
            submitButtonText={submitButtonText}
            secondaryButtonText={secondaryButtonText}
            onClickSecondaryButton={onClickSecondaryButton}
          />
        </React.Fragment>
      )}
    </Stack>
  );
};
