export const primaryButtonStyles = {
  root: {
    width: 220,
  },
};

export const primaryDarkButtonStyles = {
  root: {
    ...primaryButtonStyles.root,
    background: "black",
    border: "1px solid black",
    height: 45,
    width: 240,
  },
  rootHovered: {
    backgroundColor: "grey",
    border: "grey",
  },
};

export const defaultDarkButtonStyles = {
  root: {
    ...primaryButtonStyles.root,
    background: "white",
    border: "1px solid grey",
    height: 45,
    width: 240,
  },
  rootHovered: {
    backgroundColor: "lightgrey",
    border: "grey",
  },
};

export const primaryDarkDialogButtonStyles = {
  root: {
    ...primaryButtonStyles.root,
    background: "black",
    border: "1px solid black",
    height: 45,
    width: 155,
  },
  rootHovered: {
    backgroundColor: "grey",
    border: "grey",
  },
};

export const defaultDarkDialogButtonStyles = {
  root: {
    ...primaryButtonStyles.root,
    background: "white",
    border: "1px solid grey",
    height: 45,
    width: 120,
  },
  rootHovered: {
    backgroundColor: "lightgrey",
    border: "grey",
  },
};

export const dropdownStyles = { dropdown: { width: 300 } };
