import { Stack, Text, TextField } from "@fluentui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFreeTextValidator } from "src/core/components/SurveyQuestions/hooks/useFreeTextValidator";
import { SurveyQuestion, SurveyQuestionOption } from "src/types/common";

export interface Props {
  surveyQuestion: SurveyQuestion;
  onChange: (
    add: boolean,
    question: SurveyQuestion,
    option?: SurveyQuestionOption,
    removeAllExistingQuestionOptions?: boolean
  ) => void;
  preselectedSurveyQuestion?: SurveyQuestion;
}

export const FreeText = (props: Props) => {
  const [currentValue, setCurrentValue] = useState("");

  const { t } = useTranslation();
  const { stringIsValid } = useFreeTextValidator(currentValue);

  const { surveyQuestion, onChange, preselectedSurveyQuestion } = props;

  useEffect(() => {
    if (preselectedSurveyQuestion) {
      setCurrentValue(preselectedSurveyQuestion.value ?? "");
    }
  }, [preselectedSurveyQuestion]);

  return (
    <Stack
      style={{ width: "100%" }}
      tokens={{ childrenGap: "5", maxWidth: 400 }}
      verticalAlign="baseline"
    >
      <Text
        variant={"medium"}
        style={{ fontWeight: 500, textAlign: "justify" }}
      >
        {surveyQuestion.displayTitle}
      </Text>
      <Stack style={{ marginTop: 15 }}>
        <TextField
          placeholder={t("resources.17")}
          value={currentValue}
          multiline
          rows={3}
          styles={{ root: { width: "100%" } }}
          onChange={(
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
          ) => {
            setCurrentValue(newValue ?? "");

            onChange(
              true,
              { ...surveyQuestion, value: newValue },
              undefined,
              true
            );
          }}
        />
        {!stringIsValid && (
          <Text
            variant={"small"}
            style={{ textAlign: "justify", color: "red", marginTop: 3 }}
          >
            {t("messages.invalidSymbolsError")}
          </Text>
        )}
      </Stack>
    </Stack>
  );
};
