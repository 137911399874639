import { DefaultButton, PrimaryButton, Stack, Text } from "@fluentui/react";
import { useTranslation } from "react-i18next";
import {
  defaultDarkButtonStyles,
  primaryDarkButtonStyles,
} from "src/core/styles/common.styles";

export interface Props {
  submitButtonText: string;
  validationError: boolean;
  onSubmit: () => void;
  secondaryButtonText?: string;
  onClickSecondaryButton?: () => void;
}

export const SurveyPageFooter = (props: Props) => {
  const {
    submitButtonText,
    validationError,
    onSubmit,
    onClickSecondaryButton,
    secondaryButtonText,
  } = props;

  const { t } = useTranslation();

  return (
    <Stack horizontalAlign="end" style={{ marginBottom: 30 }}>
      <Stack
        horizontal
        horizontalAlign="end"
        verticalAlign="center"
        style={{ marginTop: 40, marginBottom: 15, width: "100%" }}
      >
        {onClickSecondaryButton && (
          <Stack
            horizontalAlign="start"
            style={{ width: "50%", marginLeft: 5 }}
          >
            <DefaultButton
              text={secondaryButtonText}
              style={{ width: 120 }}
              styles={defaultDarkButtonStyles}
              onClick={onClickSecondaryButton}
            />
          </Stack>
        )}
        <Stack horizontalAlign="end" style={{ width: "50%" }}>
          <PrimaryButton
            style={{ width: 140, marginRight: 5 }}
            styles={primaryDarkButtonStyles}
            text={submitButtonText}
            onClick={() => {
              onSubmit();
            }}
          />
        </Stack>
      </Stack>
      {validationError && (
        <Text style={{ color: "red", marginRight: 10 }}>
          {t("resources.19")}
        </Text>
      )}
    </Stack>
  );
};
