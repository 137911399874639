import { API_BASE_URL } from "src/constants";
import { get } from "src/core/utils/api";
import { Brand, SurveyResponse } from "src/types/common";

const createSurveyResponseService = () => {
  const fetchBrandOverallSurveyResponse = async (
    accessToken: string,
    brand: Brand,
    marketId: number,
    language?: string
  ): Promise<SurveyResponse> => {
    const response = await get<SurveyResponse>(
      accessToken,
      API_BASE_URL +
        `/responses/overall/brands/${
          brand.code
        }?marketId=${marketId}&language=${language ?? ""}`
    );

    return response;
  };

  const fetchModelSurveyResponse = async (
    accessToken: string,
    modelNumber: string,
    brand: Brand,
    marketId: number,
    language?: string
  ): Promise<SurveyResponse> => {
    const response = await get<SurveyResponse>(
      accessToken,
      API_BASE_URL +
        `/responses/model/brands/${
          brand.code
        }/models/${modelNumber}?marketId=${marketId}&language=${language ?? ""}`
    );

    return response;
  };

  return {
    fetchBrandOverallSurveyResponse,
    fetchModelSurveyResponse,
  };
};
export const surveyResponseService = createSurveyResponseService();
