import { API_BASE_URL } from "src/constants";
import { get } from "src/core/utils/api";
import { AppConstants, Market, Region } from "src/types/common";

const createManagementService = () => {
  const fetchRegions = async (accessToken: string): Promise<Region[]> => {
    const response = await get<Region[]>(
      accessToken,
      API_BASE_URL + `/management/regions`
    );

    return response;
  };

  const fetchRegionMarkets = async (
    regionId: number,
    accessToken: string
  ): Promise<Market[]> => {
    const response = await get<Market[]>(
      accessToken,
      API_BASE_URL + `/management/regions/${regionId}/markets`
    );

    return response;
  };

  const fetchAppConstants = async (
    accessToken: string
  ): Promise<AppConstants> => {
    const response = await get<AppConstants>(
      accessToken,
      API_BASE_URL + `/management/constants`
    );

    return response;
  };

  return {
    fetchRegions,
    fetchRegionMarkets,
    fetchAppConstants,
  };
};

export const managementService = createManagementService();
