import {
  ComboBox,
  IComboBox,
  IComboBoxOption,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFreeTextValidator } from "src/core/components/SurveyQuestions/hooks/useFreeTextValidator";
import { SurveyQuestion, SurveyQuestionOption } from "src/types/common";

export interface Props {
  surveyQuestion: SurveyQuestion;
  onChange: (
    add: boolean,
    question: SurveyQuestion,
    option: SurveyQuestionOption,
    removeAllExistingQuestionOptions: boolean
  ) => void;
  preselectedSurveyQuestionOptions?: SurveyQuestionOption[];
}

export const SelectComboBox = (props: Props) => {
  const [comboOptions, setComboOptions] = useState<IComboBoxOption[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<
    SurveyQuestionOption[]
  >([]);
  const [selectedOptionKeys, setSelectedOptionKeys] = useState<string[]>([]);

  const [commentDisabled, setCommentDisabled] = useState(true);
  const [comment, setComment] = useState("");

  const [hasFreeText, setHasFreeText] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { t } = useTranslation();
  const { stringIsValid } = useFreeTextValidator(comment);

  const { surveyQuestion, onChange, preselectedSurveyQuestionOptions } = props;

  const resetOptions = (
    options?: SurveyQuestionOption[],
    disableAll: boolean = false
  ) => {
    let optionsToSet: IComboBoxOption[] = [];

    if (options && options.length > 0) {
      options.forEach((option: SurveyQuestionOption) => {
        optionsToSet.push({
          key: option.id,
          text: option.displayValue,
          data: option,
          disabled: disableAll && option.type !== "NoComment",
          styles: {
            optionText: {
              overflow: "visible",
              whiteSpace: "normal",
            },
          },
        });
      });

      setComboOptions(optionsToSet);
    }
  };

  const onSelectionChange = (option?: IComboBoxOption) => {
    if (option) {
      if (option?.selected) {
        if (option.data.type === "NoComment") {
          resetOptions(surveyQuestion.surveyQuestionOptions, true);

          setSelectedOptionKeys([option.data.id]);
          setSelectedOptions([option.data]);

          onChange(true, surveyQuestion, option.data, true);
        } else {
          setSelectedOptionKeys([...selectedOptionKeys, option.data.id]);
          setSelectedOptions([...selectedOptions, option.data]);

          onChange(true, surveyQuestion, option.data, false);
        }
      } else {
        if (option.data.type === "NoComment") {
          resetOptions(surveyQuestion.surveyQuestionOptions);
          setSelectedOptionKeys([]);
          setSelectedOptions([]);

          onChange(false, surveyQuestion, option.data, true);
        } else {
          const updatedSelection = selectedOptions.filter(function (
            opt: SurveyQuestionOption
          ) {
            return opt.id !== option.key;
          });

          const updatedSelectionKeys = selectedOptionKeys.filter(function (
            keyValue: string
          ) {
            return keyValue !== option.key;
          });

          setSelectedOptionKeys(updatedSelectionKeys);
          setSelectedOptions(updatedSelection);

          onChange(false, surveyQuestion, option.data, false);
        }
      }
    }
  };

  useEffect(() => {
    resetOptions(surveyQuestion.surveyQuestionOptions);
  }, [surveyQuestion.surveyQuestionOptions]);

  useEffect(() => {
    if (selectedOptions.some((e) => e.type === "FreeText")) {
      setCommentDisabled(false);
    } else {
      setComment("");
      setCommentDisabled(true);
    }
  }, [selectedOptions]);

  useEffect(() => {
    if (
      surveyQuestion.surveyQuestionOptions.some((e) => e.type === "FreeText")
    ) {
      setHasFreeText(true);
    }
  }, [surveyQuestion.surveyQuestionOptions]);

  useEffect(() => {
    if (
      preselectedSurveyQuestionOptions &&
      preselectedSurveyQuestionOptions.length > 0
    ) {
      const preselectedOptions = preselectedSurveyQuestionOptions.filter(
        function (opt: SurveyQuestionOption) {
          return opt.surveyQuestionId === surveyQuestion.id;
        }
      );

      if (preselectedOptions.length > 0) {
        let preselectedOptionsKeys: string[] = [];

        preselectedOptions.forEach((opt: SurveyQuestionOption) => {
          preselectedOptionsKeys.push(opt.id);

          if (opt.type === "FreeText") {
            setComment(opt?.customValue || "");
          }

          if (opt.type === "NoComment") {
            resetOptions(surveyQuestion.surveyQuestionOptions, true);
          }
        });

        setSelectedOptionKeys(preselectedOptionsKeys);
        setSelectedOptions(preselectedOptions);
      }
    }
  }, [preselectedSurveyQuestionOptions, surveyQuestion, surveyQuestion.id]);

  return (
    <Stack
      style={{ width: "100%" }}
      tokens={{ childrenGap: "5", maxWidth: 400 }}
      verticalAlign="baseline"
    >
      <Text
        variant={"medium"}
        style={{ fontWeight: 500, textAlign: "justify" }}
      >
        {surveyQuestion.displayTitle}
      </Text>
      <ComboBox
        selectedKey={selectedOptionKeys}
        multiSelect
        allowFreeform={false}
        iconButtonProps={{ styles: { root: { color: "black" } } }}
        options={comboOptions}
        styles={{ root: { marginTop: 5 } }}
        caretDownButtonStyles={{ root: { color: "black" } }}
        errorMessage={errorMessage}
        onChange={(
          event: React.FormEvent<IComboBox>,
          option?: IComboBoxOption
        ) => {
          onSelectionChange(option);
        }}
      />
      {hasFreeText && (
        <Stack style={{ marginTop: 15 }}>
          <TextField
            disabled={commentDisabled}
            placeholder={t("resources.17")}
            value={comment}
            multiline
            rows={3}
            styles={{ root: { width: "100%" } }}
            onChange={(
              event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              newValue?: string
            ) => {
              setComment(newValue ?? "");

              let freeTextOption = surveyQuestion.surveyQuestionOptions.find(
                (item: SurveyQuestionOption) => {
                  return item.type === "FreeText";
                }
              );

              if (freeTextOption) {
                const optionWithComment = {
                  ...freeTextOption,
                  customValue: newValue || "",
                };

                onChange(true, surveyQuestion, optionWithComment, false);
              }
            }}
          />
          {!stringIsValid && (
            <Text
              variant={"small"}
              style={{ textAlign: "justify", color: "red", marginTop: 3 }}
            >
              {t("messages.invalidSymbolsError")}
            </Text>
          )}
        </Stack>
      )}
    </Stack>
  );
};
