import { ImageFit, Modal, Stack, Text } from "@fluentui/react";
import { ChromeCloseIcon } from "@fluentui/react-icons-mdl2";
import { Image } from "src/core/components/Image";
import { Model } from "src/types/common";

interface Props {
  model: Model | undefined;
  showImageModal: boolean;
  setShowImageModal: (value: boolean) => void;
}

export const ModelImageModal = (props: Props) => {
  const { model, showImageModal, setShowImageModal } = props;

  return (
    <Modal
      titleAriaId={model?.number}
      isOpen={showImageModal}
      onDismiss={() => {
        setShowImageModal(false);
      }}
    >
      <Stack style={{ position: "relative" }}>
        <ChromeCloseIcon
          style={{
            position: "absolute",
            right: 10,
            top: 10,
            zIndex: 99,
            cursor: "pointer",
          }}
          onClick={() => setShowImageModal(false)}
        />
        <Image
          style={{ height: "auto", width: "100%", top: 0 }}
          imageFit={ImageFit.contain}
          src={model?.imageUrl}
        />
        <Text as="span" style={{ textAlign: "center" }}>
          {model?.brandName} | {model?.number}
        </Text>
      </Stack>
    </Modal>
  );
};
