import { Text } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { appConstants } from "src/appConstants";
import { ModelsGrid } from "src/core/components/ModelsGrid";
import Page from "src/core/components/Page/Page";
import PageContent from "src/core/components/PageContent";
import { PageHeader } from "src/core/components/PageHeader";
import Skeleton from "src/core/components/Skeleton";
import { useAuth } from "src/core/hooks/useAuth";
import { brandService } from "src/core/services/brandService";
import { mainAppState } from "src/recoil/atoms";
import { Model } from "src/types/common";

export const BrandModels = () => {
  const [appState, setAppState] = useRecoilState(mainAppState);

  const [loading, setLoading] = useState(true);
  const [onlyReviews, setOnlyReviews] = useState(false);
  const [contentPadding, setContentPadding] = useState<number | undefined>();
  const [searchValue, setSearchValue] = useState<string | undefined>();

  const [modelsList, setModelsList] = useState<Model[]>([]);

  const { accessToken, success } = useAuth();
  const { t } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (appState.selectedRegion && appState.selectedBrand) {
        if (success) {
          const brandModelsResponse = await brandService.fetchBrandModels(
            accessToken,
            appState.selectedBrand.code ?? "",
            appState.selectedRegion?.id ?? 0,
            appState.selectedMarket?.id ?? 0,
            appConstants()?.modelSurveyId
          );

          setModelsList(brandModelsResponse);

          if (
            appState.selectedRegion.onlyReviews ||
            appState.selectedMarket?.onlyReviews
          ) {
            setOnlyReviews(true);
          }

          setLoading(false);
        }
      } else {
        navigate(`/`);
      }
    })();
  }, [
    accessToken,
    appState.selectedBrand,
    appState.selectedMarket?.id,
    appState.selectedMarket?.onlyReviews,
    appState.selectedRegion,
    navigate,
    success,
  ]);

  return (
    <Page>
      <Skeleton loading={loading}>
        <PageHeader
          header={appState.selectedBrand?.name || ""}
          subHeader={`${appConstants()?.activeCollectionId} | ${t(
            `markets.${appState.selectedMarket?.name}`
          )}`}
          onBackClick={() => {
            navigate(`/model-brands`);
          }}
          searchEnabled={modelsList.length > 0 ? true : undefined}
          setSearchValue={setSearchValue}
          shareBlockHeight={setContentPadding}
        />
        <PageContent paddingTop={contentPadding}>
          {modelsList && (
            <React.Fragment>
              {modelsList.length > 0 && (
                <ModelsGrid
                  onlyList
                  imageIsClickable
                  onlyReviews={onlyReviews}
                  models={modelsList}
                  onClick={(model: Model) => {
                    setAppState({ ...appState, selectedModel: model });
                    if (model.hasResponse) {
                      navigate(`/model-survey-review`);
                    } else {
                      if (!onlyReviews) {
                        navigate(`/model-survey`);
                      }
                    }
                  }}
                  filterValue={searchValue}
                />
              )}
              {modelsList.length === 0 && (
                <Text as="h3">{t(`messages.noModels`)}</Text>
              )}
            </React.Fragment>
          )}
        </PageContent>
      </Skeleton>
    </Page>
  );
};
