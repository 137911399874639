import {
  InteractionStatus,
  IPublicClientApplication,
} from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { AppSurvey } from "src/pages/AppSurvey/AppSurvey";
import { AppSurveySuccess } from "src/pages/AppSurvey/AppSurveySuccess";
import { BrandModels } from "src/pages/BrandModels/BrandModels";
import { ModelSurvey } from "src/pages/ModelSurvey/ModelSurvey";
import { ModelSurveySuccess } from "src/pages/ModelSurvey/ModelSurveySuccess";
import { ModelSurveyBrands } from "src/pages/ModelSurveyBrands/ModelSurveyBrands";
import { ModelSurveyResponseReview } from "src/pages/ModelSurveyResponseReview/ModelSurveyResponseReview";
import { NoAccess } from "src/pages/NoAccess/NoAccess";
import { OverallSurvey } from "src/pages/OverallSurvey/OverallSurvey";
import { OverallSurveySuccess } from "src/pages/OverallSurvey/OverallSurveySuccess";
import { OverallSurveyResponseReview } from "src/pages/OverallSurveyResponseReview/OverallSurveyResponseReview";
import { SignIn } from "src/pages/SignIn/SignIn";
import "./App.css";
import { loginRedirectRequest } from "./authConfig";
import { OverallBrands } from "./pages/OverallBrands/OverallBrands";
import { Welcome } from "./pages/Welcome/Welcome";

type Props = {
  pca: IPublicClientApplication;
};

function App({ pca }: Props) {
  return (
    <MsalProvider instance={pca}>
      <RecoilRoot>
        <Router>
          <Routes>
            <Route
              path="/"
              element={
                <RequireAuth>
                  <Welcome />
                </RequireAuth>
              }
            />
            <Route
              path="/overall-brands"
              element={
                <RequireAuth>
                  <OverallBrands />
                </RequireAuth>
              }
            />
            <Route
              path="/overall-survey"
              element={
                <RequireAuth>
                  <OverallSurvey />
                </RequireAuth>
              }
            />
            <Route
              path="/app-survey"
              element={
                <RequireAuth>
                  <AppSurvey />
                </RequireAuth>
              }
            />
            <Route
              path="/app-survey-success"
              element={
                <RequireAuth>
                  <AppSurveySuccess />
                </RequireAuth>
              }
            />
            <Route
              path="/overall-survey-review"
              element={
                <RequireAuth>
                  <OverallSurveyResponseReview />
                </RequireAuth>
              }
            />
            <Route
              path="/overall-survey-success"
              element={
                <RequireAuth>
                  <OverallSurveySuccess />
                </RequireAuth>
              }
            />
            <Route
              path="/model-survey-success"
              element={
                <RequireAuth>
                  <ModelSurveySuccess />
                </RequireAuth>
              }
            />
            <Route
              path="/model-survey-review"
              element={
                <RequireAuth>
                  <ModelSurveyResponseReview />
                </RequireAuth>
              }
            />
            <Route
              path="/model-brands"
              element={
                <RequireAuth>
                  <ModelSurveyBrands />
                </RequireAuth>
              }
            />
            <Route
              path="/brand-models"
              element={
                <RequireAuth>
                  <BrandModels />
                </RequireAuth>
              }
            />
            <Route
              path="/model-survey"
              element={
                <RequireAuth>
                  <ModelSurvey />
                </RequireAuth>
              }
            />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/no-access" element={<NoAccess />} />
          </Routes>
        </Router>
      </RecoilRoot>
    </MsalProvider>
  );
}

// helper to define auth required routes
function RequireAuth({ children }: { children: JSX.Element }) {
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();

  if (inProgress === InteractionStatus.None && !isAuthenticated) {
    instance.loginRedirect(loginRedirectRequest);
    return <UnauthenticatedTemplate>Loading...</UnauthenticatedTemplate>;
  } else {
    return <AuthenticatedTemplate>{children}</AuthenticatedTemplate>;
  }
}

export default App;
