import { Stack, Text } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SurveySaveDialog } from "src/core/components/SurveyQuestions/components/SurveySaveDialog";
import { SurveyQuestions } from "src/core/components/SurveyQuestions/SurveyQuestions";
import { useAuth } from "src/core/hooks/useAuth";
import { surveyService } from "src/core/services/surveyService";
import { Survey, SurveyQuestion, SurveyQuestionOption } from "src/types/common";

export interface Props {
  survey: Survey;
  marketId: number;
  collection?: string;
  brandCode?: string;
  modelNumber?: string;
  onSuccess: () => void;
  shareCurrentPageNumber?: (page: number) => void;
}

export const PaginatedSurvey = (props: Props) => {
  const {
    survey,
    onSuccess,
    marketId,
    brandCode,
    modelNumber,
    collection,
    shareCurrentPageNumber,
  } = props;

  const [selectedOptions, setSelectedOptions] = useState<
    SurveyQuestionOption[]
  >([]);
  const [selectedQuestions, setSelectedQuestions] = useState<SurveyQuestion[]>(
    []
  );

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const [errorOnSave, setErrorOnSave] = useState<boolean>(false);
  const [errorOnSaveMessage, setErrorOnSaveMessage] = useState<string>();

  const { t } = useTranslation();
  const { accessToken } = useAuth();

  const onNextClick = (
    selectedSurveyQuestions: SurveyQuestion[],
    selectedSurveyQuestionOptions: SurveyQuestionOption[],
    removedSurveyQuestions?: SurveyQuestion[]
  ) => {
    // do stuf with selected answers
    // getting a distinct list of question ids
    if (selectedSurveyQuestionOptions.length > 0) {
      let questionIds: number[] = [];
      selectedSurveyQuestionOptions.forEach((opt: SurveyQuestionOption) => {
        const existingIndex = questionIds.findIndex(function (num: number) {
          return num === opt.surveyQuestionId;
        });

        if (existingIndex < 0) {
          questionIds.push(opt.surveyQuestionId);
        }
      });

      // before adding new values it is need to remove existing ones
      let filteredOptionsList = selectedOptions;

      questionIds.forEach((questionId: number) => {
        filteredOptionsList = filteredOptionsList.filter(function (
          opt: SurveyQuestionOption
        ) {
          return opt.surveyQuestionId !== questionId;
        });
      });

      setSelectedOptions([
        ...selectedSurveyQuestionOptions,
        ...filteredOptionsList,
      ]);
    }

    if (removedSurveyQuestions && removedSurveyQuestions.length > 0) {
      let filteredQuestionsList = selectedQuestions;

      removedSurveyQuestions.forEach((question: SurveyQuestion) => {
        filteredQuestionsList = filteredQuestionsList.filter(function (
          q: SurveyQuestion
        ) {
          return q.id !== question.id;
        });
      });

      setSelectedQuestions(filteredQuestionsList);
    }

    if (selectedSurveyQuestions.length > 0) {
      let filteredQuestionsList = selectedQuestions;

      selectedSurveyQuestions.forEach((question: SurveyQuestion) => {
        filteredQuestionsList = filteredQuestionsList.filter(function (
          q: SurveyQuestion
        ) {
          return q.id !== question.id;
        });
      });

      setSelectedQuestions([
        ...selectedSurveyQuestions,
        ...filteredQuestionsList,
      ]);
    }

    if (survey.surveyPages.length > currentPage + 1) {
      if (shareCurrentPageNumber) {
        shareCurrentPageNumber(currentPage + 1);
      }
      setCurrentPage(currentPage + 1);
    } else {
      setErrorOnSave(false);
      setErrorOnSaveMessage(undefined);

      setShowConfirm(true);
    }
  };

  const onSubmit = async () => {
    setErrorOnSave(false);

    const saveResult = await surveyService.saveSurveyResponse(
      accessToken,
      survey.id,
      selectedQuestions,
      selectedOptions,
      marketId,
      modelNumber,
      brandCode,
      collection
    );

    if (saveResult.success) {
      setShowConfirm(false);
      onSuccess();
    } else {
      setErrorOnSave(true);
      setErrorOnSaveMessage(saveResult.errorMessage);
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0 });
    if (shareCurrentPageNumber) {
      shareCurrentPageNumber(currentPage);
    }
  }, [currentPage, shareCurrentPageNumber]);

  return (
    <Stack horizontalAlign="center">
      <SurveySaveDialog
        showConfirm={showConfirm}
        hideConfirm={() => {
          setShowConfirm(false);
        }}
        onSubmit={onSubmit}
        error={errorOnSave}
        errorMessage={errorOnSaveMessage}
      />
      {survey && (
        <React.Fragment>
          <SurveyQuestions
            key={`q-page-index-${currentPage}`}
            surveyQuestionGroups={
              survey.surveyPages[currentPage].surveyQuestionGroups
            }
            onSave={onNextClick}
            submitButtonText={
              survey.surveyPages.length === currentPage + 1
                ? t("resources.59")
                : t("resources.20")
            }
            secondaryButtonText={t("resources.68")}
            onClickSecondaryButton={
              currentPage === 0
                ? undefined
                : () => {
                    if (shareCurrentPageNumber) {
                      shareCurrentPageNumber(currentPage - 1);
                    }
                    setCurrentPage(currentPage - 1);
                  }
            }
            preselectedSurveyQuestionOptions={selectedOptions}
            preselectedSurveyQuestions={selectedQuestions}
          />
          <Text
            variant={"medium"}
            style={{
              fontWeight: 600,
              marginBottom: 20,
            }}
          >
            {currentPage + 1}/{survey.surveyPages.length}
          </Text>
        </React.Fragment>
      )}
    </Stack>
  );
};
