import { Text } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { appConstants } from "src/appConstants";
import { BrandsGrid } from "src/core/components/BrandsGrid";
import Page from "src/core/components/Page/Page";
import PageContent from "src/core/components/PageContent";
import { PageHeader } from "src/core/components/PageHeader";
import Skeleton from "src/core/components/Skeleton";
import { useAuth } from "src/core/hooks/useAuth";
import { brandService } from "src/core/services/brandService";
import { mainAppState } from "src/recoil/atoms";
import { Brand } from "src/types/common";

export const ModelSurveyBrands = () => {
  const [appState, setAppState] = useRecoilState(mainAppState);

  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [contentPadding, setContentPadding] = useState<number | undefined>();

  const [brandsList, setBrandsList] = useState<Brand[]>([]);

  const { accessToken, success } = useAuth();
  const { t } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (appState.selectedRegion) {
        if (success) {
          const brandsResponse =
            await brandService.fetchBrandsByRegionAndMarket(
              accessToken,
              appState.selectedRegion?.id ?? 0,
              appState.selectedMarket?.id ?? 0,
              undefined,
              appConstants()?.modelSurveyId
            );

          setBrandsList(brandsResponse);
          setLoading(false);
        }
      } else {
        navigate(`/`);
      }
    })();
  }, [
    accessToken,
    appState.selectedMarket?.id,
    appState.selectedRegion,
    navigate,
    success,
  ]);

  return (
    <Page>
      <Skeleton loading={loading}>
        <PageHeader
          header={`${appConstants()?.activeCollectionId} | ${t(
            `markets.${appState.selectedMarket?.name}`
          )}`}
          subHeader={undefined}
          onBackClick={() => {
            navigate(`/`);
          }}
          searchEnabled={brandsList.length > 0 ? true : undefined}
          setSearchValue={setSearchValue}
          shareBlockHeight={setContentPadding}
        />
        <PageContent paddingTop={contentPadding}>
          {brandsList && (
            <React.Fragment>
              {brandsList.length > 0 && (
                <BrandsGrid
                  brands={brandsList}
                  onClick={(brand: Brand) => {
                    setAppState({ ...appState, selectedBrand: brand });
                    navigate(`/brand-models`);
                  }}
                  filterValue={searchValue}
                />
              )}
              {brandsList.length === 0 && (
                <Text as="h3">{t(`messages.noBrands`)}</Text>
              )}
            </React.Fragment>
          )}
        </PageContent>
      </Skeleton>
    </Page>
  );
};
