import * as React from "react";
import { useTranslation } from "react-i18next";
import { ConfirmDialog } from "src/core/components/ConfirmDialog";
import {
  defaultDarkDialogButtonStyles,
  primaryDarkDialogButtonStyles,
} from "src/core/styles/common.styles";

export interface Props {
  showConfirm: boolean;
  hideConfirm: () => void;
  onSubmit: () => void;
  error?: boolean;
  errorMessage?: string;
}

export const SurveySaveDialog: React.FunctionComponent<Props> = (
  props: Props
) => {
  const { showConfirm, hideConfirm, onSubmit, error, errorMessage } = props;

  const { t } = useTranslation();

  return (
    <ConfirmDialog
      showConfirm={showConfirm}
      hideConfirm={hideConfirm}
      onSubmit={onSubmit}
      title={t("resources.56")}
      subTitle={t("resources.57")}
      cancelButton={t("resources.58")}
      continueButton={t("resources.59")}
      errorMessage={t("messages.unexpectedError")}
      error={error}
      submitButtonStyles={primaryDarkDialogButtonStyles}
      cancelButtonStyles={defaultDarkDialogButtonStyles}
    />
  );
};
