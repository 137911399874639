import { ChoiceGroup, IChoiceGroupOption, Stack, Text } from "@fluentui/react";
import { useEffect, useState } from "react";
import { SurveyQuestion, SurveyQuestionOption } from "src/types/common";

export interface Props {
  surveyQuestion: SurveyQuestion;
  onChange: (
    add: boolean,
    question: SurveyQuestion,
    option: SurveyQuestionOption,
    removeAllExistingQuestionOptions: boolean
  ) => void;
  preselectedSurveyQuestionOption?: SurveyQuestionOption;
}

export const RatingRadio = (props: Props) => {
  const { surveyQuestion, onChange, preselectedSurveyQuestionOption } = props;

  const [selectedKey, setSelectedKey] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  const [ratingOptions, setRatingOptions] = useState<IChoiceGroupOption[]>([]);
  const [ratingOptionsWithLabels, setRatingOptionsWithLabels] = useState<
    IChoiceGroupOption[]
  >([]);

  useEffect(() => {
    let optionsWithNoLabels: IChoiceGroupOption[] = [];
    let optionsWithLabels: IChoiceGroupOption[] = [];

    if (
      surveyQuestion.surveyQuestionOptions &&
      surveyQuestion.surveyQuestionOptions.length > 0
    ) {
      surveyQuestion.surveyQuestionOptions.forEach(
        (option: SurveyQuestionOption) => {
          optionsWithNoLabels.push({
            key: option.id,
            text: option.displayValue,
            styles: {
              root: {
                width: `${400 / surveyQuestion.surveyQuestionOptions.length}%`,
                paddingLeft:
                  400 / surveyQuestion.surveyQuestionOptions.length / 2 - 12,
              },
            },
          });
          optionsWithLabels.push({
            key: option.id,
            text: option.displayTitle ?? "",
          });
        }
      );

      setRatingOptions(optionsWithNoLabels);
      setRatingOptionsWithLabels(optionsWithLabels);
    }
  }, [surveyQuestion.surveyQuestionOptions]);

  useEffect(() => {
    if (preselectedSurveyQuestionOption) {
      setSelectedKey(parseInt(preselectedSurveyQuestionOption.id));
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [preselectedSurveyQuestionOption]);

  return (
    <Stack
      tokens={{ childrenGap: "5", maxWidth: 400 }}
      verticalAlign="baseline"
    >
      <Text
        variant={"medium"}
        style={{ fontWeight: 500, textAlign: "justify" }}
      >
        {surveyQuestion.displayTitle}
      </Text>
      {!loading && (
        <ChoiceGroup
          styles={{
            root: { marginTop: 0 },
            flexContainer: {
              display: "flex",
              width: "100%",
            },
          }}
          defaultSelectedKey={selectedKey}
          options={ratingOptions}
          onChange={(
            _e?: React.FormEvent<HTMLElement | HTMLInputElement>,
            option?: IChoiceGroupOption
          ) => {
            if (option?.key) {
              const selectedOption = surveyQuestion.surveyQuestionOptions.find(
                (item: SurveyQuestionOption) => {
                  return option?.key === item?.id;
                }
              );

              if (selectedOption) {
                onChange(true, surveyQuestion, selectedOption, true);
              }
            }
          }}
        />
      )}
      <Stack style={{ marginTop: 3 }} horizontal>
        {ratingOptionsWithLabels.map((option: IChoiceGroupOption, index) => {
          if (index === 0) {
            return (
              <span
                key={`r-l-${index}`}
                style={{
                  width: "33%",
                  display: "inline-block",
                  textAlign: "left",
                  fontSize: "12px",
                }}
              >
                {option.text}
              </span>
            );
          }

          if (index === Math.round(ratingOptionsWithLabels.length / 2) - 1) {
            return (
              <span
                key={`r-l-${index}`}
                style={{
                  width: "33%",
                  display: "inline-block",
                  textAlign: "center",
                  fontSize: "12px",
                }}
              >
                {option.text}
              </span>
            );
          }

          if (index === ratingOptionsWithLabels.length - 1) {
            return (
              <span
                key={`r-l-${index}`}
                style={{
                  width: "33%",
                  display: "inline-block",
                  textAlign: "right",
                  fontSize: "12px",
                }}
              >
                {option.text}
              </span>
            );
          }

          return "";
        })}
      </Stack>
    </Stack>
  );
};
