import React from "react";
import { CheckBoxRadio } from "src/core/components/SurveyQuestions/components/CheckBoxRadio";
import { FreeText } from "src/core/components/SurveyQuestions/components/FreeText";
import { ImageUpload } from "src/core/components/SurveyQuestions/components/ImageUpload";
import { RatingRadio } from "src/core/components/SurveyQuestions/components/RatingRadio";
import { SelectComboBox } from "src/core/components/SurveyQuestions/components/SelectComboBox";
import { primaryDarkButtonStyles } from "src/core/styles/common.styles";
import { SurveyQuestion, SurveyQuestionOption } from "src/types/common";

export interface Props {
  surveyQuestion: SurveyQuestion;
  onQuestionChange: (
    add: boolean,
    question: SurveyQuestion,
    option?: SurveyQuestionOption,
    removeAllExistingQuestionOptions?: boolean
  ) => void;
  preselectedSurveyQuestionOptions?: SurveyQuestionOption[];
  preselectedSurveyQuestions?: SurveyQuestion[];
}

export const SurveyQuestionRender = (props: Props) => {
  const {
    surveyQuestion,
    onQuestionChange,
    preselectedSurveyQuestionOptions,
    preselectedSurveyQuestions,
  } = props;

  const renderSurveyQuestion = (question: SurveyQuestion) => {
    switch (question.type) {
      case "Rating": {
        const valueToPreselect = preselectedSurveyQuestionOptions?.find(
          (opt: SurveyQuestionOption) =>
            opt.surveyQuestionId === surveyQuestion.id
        );

        return (
          <RatingRadio
            key={`q-id-${question.id}`}
            surveyQuestion={question}
            onChange={onQuestionChange}
            preselectedSurveyQuestionOption={valueToPreselect}
          />
        );
      }

      case "MultiOpt":
        return (
          <SelectComboBox
            key={`q-id-${question.id}`}
            surveyQuestion={question}
            onChange={onQuestionChange}
            preselectedSurveyQuestionOptions={preselectedSurveyQuestionOptions}
          />
        );
      case "SingleOpt": {
        const valueToPreselect = preselectedSurveyQuestionOptions?.find(
          (opt: SurveyQuestionOption) =>
            opt.surveyQuestionId === surveyQuestion.id
        );

        return (
          <CheckBoxRadio
            key={`q-id-${question.id}`}
            surveyQuestion={question}
            onChange={onQuestionChange}
            preselectedSurveyQuestionOption={valueToPreselect}
          />
        );
      }

      case "FreeText": {
        const valueToPreselect = preselectedSurveyQuestions?.find(
          (opt: SurveyQuestion) => opt.id === surveyQuestion.id
        );

        return (
          <FreeText
            key={`q-id-${question.id}`}
            surveyQuestion={question}
            onChange={onQuestionChange}
            preselectedSurveyQuestion={valueToPreselect}
          />
        );
      }

      case "Image": {
        const valueToPreselect = preselectedSurveyQuestions?.find(
          (opt: SurveyQuestion) => opt.id === surveyQuestion.id
        );

        return (
          <ImageUpload
            key={`q-id-${question.id}`}
            surveyQuestion={question}
            onChange={onQuestionChange}
            preselectedSurveyQuestion={valueToPreselect}
            buttonStyles={primaryDarkButtonStyles}
          />
        );
      }

      default:
        return;
    }
  };

  return (
    <React.Fragment>{renderSurveyQuestion(surveyQuestion)}</React.Fragment>
  );
};
