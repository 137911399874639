import { useEffect, useState } from "react";
import { appConstants } from "src/appConstants";

export function useFreeTextValidator(value: string) {
  const [stringIsValid, setStringIsValid] = useState(true);

  useEffect(() => {
    const validationRegex = appConstants()?.freeTextValidationRegex;

    if (validationRegex) {
      var regExp = new RegExp(validationRegex);

      setStringIsValid(!regExp.test(value));
    }
  }, [value]);

  return { stringIsValid: stringIsValid };
}
