import { CSSProperties } from "react";

export const headerLogoStyle: CSSProperties = {
  width: "220px",
  height: "auto",
  paddingTop: 40,
  paddingBottom: 40,
};

export const rateThisAppStyle: CSSProperties = {
  marginTop: 40,
  textDecoration: "underline",
  cursor: "pointer",
};

export const signOutStyle: CSSProperties = {
  marginTop: 50,
  textDecoration: "underline",
  cursor: "pointer",
};

export const dropdownStyles = { dropdown: { width: 300 } };
