import { Stack, Text } from "@fluentui/react";
import { useState } from "react";
import { Image } from "src/core/components/Image";
import { ModelImageModal } from "src/core/components/ModelImageModal";
import { Model } from "src/types/common";

interface Props {
  model: Model | undefined;
}

export const Header = (props: Props) => {
  const { model } = props;

  const [showImageModal, setShowImageModal] = useState(false);
  const [imageModalModel, setImageModalModel] = useState<Model>();

  return (
    <Stack horizontal verticalAlign="center" style={{ marginTop: 5 }}>
      <ModelImageModal
        model={imageModalModel}
        showImageModal={showImageModal}
        setShowImageModal={setShowImageModal}
      />
      <Image
        src={model?.imageUrl}
        onClick={() => {
          setImageModalModel(model);
          setShowImageModal(true);
        }}
        style={{ width: "80px", marginRight: 10, cursor: "pointer" }}
      />
      <Text
        style={{
          fontWeight: "normal",
          marginTop: 5,
          marginBottom: 5,
          fontSize: "1em",
          textAlign: "left",
          maxWidth: "250px",
        }}
      >{`${model?.number}
      | ${model?.type}
  | ${model?.gender}
  | ${model?.material}
  | ${model?.size}
  ${
    model?.price && model.currency ? `| ${model?.price} ${model?.currency}` : ""
  }
  | ${model?.fitting}`}</Text>
    </Stack>
  );
};
