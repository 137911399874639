import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { appConstants } from "src/appConstants";
import { ConfirmDialog } from "src/core/components/ConfirmDialog";
import { OnePageSurvey } from "src/core/components/OnePageSurvey";
import Page from "src/core/components/Page/Page";
import PageContent from "src/core/components/PageContent";
import { PageHeader } from "src/core/components/PageHeader";
import Skeleton from "src/core/components/Skeleton";
import { useAuth } from "src/core/hooks/useAuth";
import { surveyService } from "src/core/services/surveyService";
import {
  defaultDarkDialogButtonStyles,
  primaryDarkDialogButtonStyles,
} from "src/core/styles/common.styles";
import { getSurveyPageTitle } from "src/core/utils/survey";
import { mainAppState } from "src/recoil/atoms";
import { Survey } from "src/types/common";

export const OverallSurvey = () => {
  const appState = useRecoilValue(mainAppState);

  const [loading, setLoading] = useState(true);
  const [showBackConfirm, setShowBackConfirm] = useState(false);
  const [overallSurvey, setOverallSurvey] = useState<Survey>();

  const { accessToken, success } = useAuth();
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();

  useEffect(() => {
    (async () => {
      if (appState.selectedRegion && appState.selectedBrand) {
        if (success) {
          const response = await surveyService.getOverallSurvey(
            accessToken,
            i18n.resolvedLanguage
          );
          setOverallSurvey(response);
          setLoading(false);
        }
      } else {
        navigate(`/`);
      }
    })();
  }, [
    accessToken,
    appState.selectedBrand,
    appState.selectedRegion,
    i18n.resolvedLanguage,
    navigate,
    success,
  ]);

  return (
    <Page>
      <Skeleton loading={loading}>
        <ConfirmDialog
          showConfirm={showBackConfirm}
          hideConfirm={() => {
            setShowBackConfirm(false);
          }}
          onSubmit={() => {
            navigate(`/overall-brands`);
          }}
          title={t("modals.confirmGoBack")}
          subTitle={t("modals.confirmGoBackSubTitle")}
          cancelButton={t("resources.58")}
          continueButton={t("modals.continue")}
          submitButtonStyles={primaryDarkDialogButtonStyles}
          cancelButtonStyles={defaultDarkDialogButtonStyles}
        />
        <PageHeader
          header={getSurveyPageTitle(
            appState.selectedBrand?.name,
            t(`markets.${appState.selectedMarket?.name}`)
          )}
          subHeader={t(`resources.36`)}
          onBackClick={() => {
            setShowBackConfirm(true);
          }}
        />
        <PageContent paddingTop={90}>
          {overallSurvey && (
            <OnePageSurvey
              key={`overall-survey`}
              survey={overallSurvey}
              marketId={appState.selectedMarket?.id || 0}
              brandCode={appState.selectedBrand?.code}
              onSuccess={() => {
                navigate(`/overall-survey-success`);
              }}
              collection={appConstants()?.activeCollectionId}
            />
          )}
        </PageContent>
      </Skeleton>
    </Page>
  );
};
