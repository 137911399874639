import {
  IButtonStyles,
  Image,
  PrimaryButton,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import { StatusCircleErrorXIcon } from "@fluentui/react-icons-mdl2";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ImageUploading, { ImageListType } from "react-images-uploading";
import { useFreeTextValidator } from "src/core/components/SurveyQuestions/hooks/useFreeTextValidator";
import { SurveyQuestion, SurveyQuestionOption } from "src/types/common";

export interface Props {
  surveyQuestion: SurveyQuestion;
  onChange: (
    add: boolean,
    question: SurveyQuestion,
    option?: SurveyQuestionOption,
    removeAllExistingQuestionOptions?: boolean
  ) => void;
  preselectedSurveyQuestion?: SurveyQuestion;
  buttonStyles?: IButtonStyles;
}

export const ImageUpload = (props: Props) => {
  const [images, setImages] = useState([]);

  const [currentValue, setCurrentValue] = useState("");
  const [hasImage, setHasImage] = useState(false);

  const { t } = useTranslation();
  const { stringIsValid } = useFreeTextValidator(currentValue);

  const { surveyQuestion, onChange, preselectedSurveyQuestion, buttonStyles } =
    props;

  useEffect(() => {
    if (hasImage) {
      setCurrentValue("");
    }
  }, [hasImage]);

  useEffect(() => {
    if (preselectedSurveyQuestion) {
      setCurrentValue(preselectedSurveyQuestion.value ?? "");
      setImages((preselectedSurveyQuestion.attachments as never[]) || []);
    }
  }, [preselectedSurveyQuestion]);

  const onImageInputChange = (
    imageList: ImageListType,
    addUpdateIndex: number[] | undefined
  ) => {
    // data for submit
    setImages(imageList as never[]);

    if (addUpdateIndex) {
      onChange(true, {
        ...surveyQuestion,
        attachments: imageList,
        value: currentValue,
      });
    }
  };

  return (
    <Stack
      style={{ width: "100%", marginTop: 40 }}
      tokens={{ childrenGap: "5", maxWidth: 400 }}
      verticalAlign="baseline"
      horizontalAlign="center"
    >
      {surveyQuestion.displayTitle && (
        <Text
          variant={"medium"}
          style={{ fontWeight: 500, textAlign: "justify" }}
        >
          {surveyQuestion.displayTitle}
        </Text>
      )}
      <ImageUploading
        value={images}
        onChange={onImageInputChange}
        acceptType={["jpg", "jpeg", "png", "gif"]}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          errors,
        }) => (
          // write your building UI
          <Stack horizontalAlign="center">
            {imageList && imageList.length === 0 && (
              <PrimaryButton
                text={t("resources.62")}
                style={{ width: 240 }}
                styles={buttonStyles}
                onClick={() => {
                  setHasImage(!hasImage);
                  onImageUpload();
                }}
              />
            )}
            {errors && (
              <Text
                variant={"medium"}
                style={{ marginTop: 5, textAlign: "justify", color: "red" }}
              >
                {errors.acceptType && (
                  <span>{t("messages.invalidAttachmentType")}</span>
                )}
              </Text>
            )}
            {imageList.map((image, index) => (
              <Stack horizontalAlign="center" key={index}>
                <Stack
                  style={{ position: "relative" }}
                  tokens={{ childrenGap: 20 }}
                  verticalAlign="baseline"
                  horizontalAlign="center"
                >
                  <StatusCircleErrorXIcon
                    style={{
                      height: 30,
                      width: 30,
                      position: "absolute",
                      right: -5,
                      top: 5,
                      cursor: "pointer",
                      zIndex: 9,
                    }}
                    onClick={() => {
                      onImageRemove(index);
                      setHasImage(false);
                      onChange(false, surveyQuestion);
                    }}
                  />
                  <Image src={image.dataURL} style={{ width: 220 }} />
                  <PrimaryButton
                    text={t("resources.63")}
                    style={{ width: 240 }}
                    styles={buttonStyles}
                    onClick={() => {
                      onImageUpdate(index);
                    }}
                  />
                </Stack>
                <Stack style={{ marginTop: 20, width: 320 }}>
                  <TextField
                    placeholder={t("resources.30")}
                    value={currentValue}
                    multiline
                    rows={3}
                    styles={{ root: { width: "100%" } }}
                    onChange={(
                      event: React.FormEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >,
                      newValue?: string
                    ) => {
                      setCurrentValue(newValue ?? "");

                      onChange(true, {
                        ...surveyQuestion,
                        attachments: imageList,
                        value: newValue,
                      });
                    }}
                  />
                  {!stringIsValid && (
                    <Text
                      variant={"small"}
                      style={{
                        textAlign: "justify",
                        color: "red",
                        marginTop: 3,
                      }}
                    >
                      {t("messages.invalidSymbolsError")}
                    </Text>
                  )}
                </Stack>
              </Stack>
            ))}
          </Stack>
        )}
      </ImageUploading>
    </Stack>
  );
};
