import { SearchBox, Stack, Text } from "@fluentui/react";
import { ChevronLeftIcon, SearchIcon } from "@fluentui/react-icons-mdl2";
import { ReactNode, useEffect, useState } from "react";

interface Props {
  header: string;
  subHeader?: string;
  customSubHeader?: ReactNode;
  onBackClick?: () => void;
  searchEnabled?: boolean;
  setSearchValue?: (newValue: string | undefined) => void;
  shareBlockHeight?: (height: number) => void;
}

export const PageHeader = (props: Props) => {
  const {
    header,
    subHeader,
    customSubHeader,
    onBackClick,
    searchEnabled,
    setSearchValue,
    shareBlockHeight,
  } = props;

  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    if (shareBlockHeight) {
      shareBlockHeight(80);
    }
  }, [shareBlockHeight]);

  return (
    <Stack
      horizontalAlign="center"
      style={{
        marginTop: 0,
        paddingBottom: 10,
        paddingTop: 0,
        position: "fixed",
        width: "100%",
        backgroundColor: "white",
        zIndex: 100,
        top: 0,
        display: "block",
      }}
    >
      <Stack horizontalAlign="center" style={{ width: "100%" }}>
        <Stack
          horizontal
          horizontalAlign="center"
          style={{ maxWidth: 540, position: "relative", width: "100%" }}
        >
          {onBackClick && (
            <ChevronLeftIcon
              onClick={() => {
                onBackClick();
              }}
              style={{
                cursor: "pointer",
                position: "absolute",
                left: 7,
                marginTop: 10,
                height: 26,
                width: 26,
              }}
            />
          )}
          {searchEnabled && setSearchValue && (
            <SearchIcon
              onClick={() => {
                if (showSearch) {
                  setSearchValue("");
                  if (shareBlockHeight) {
                    shareBlockHeight(80);
                  }
                }

                if (!showSearch && shareBlockHeight) {
                  shareBlockHeight(120);
                }

                setShowSearch(!showSearch);
              }}
              style={{
                cursor: "pointer",
                position: "absolute",
                right: 10,
                marginTop: 10,
                marginRight: 10,
                height: 26,
                width: 26,
              }}
            />
          )}
          <Text
            as="h1"
            style={{
              fontWeight: "600",
              marginBottom: 0,
              marginTop: 10,
              fontSize: "1.4em",
              paddingLeft: 25,
              paddingRight: 25,
              width: "100%",
              textAlign: "center",
              display: "block",
            }}
          >
            {header}
          </Text>
        </Stack>
      </Stack>
      <Stack horizontalAlign="center">
        {customSubHeader ? (
          customSubHeader
        ) : (
          <Text
            style={{
              fontWeight: "normal",
              marginTop: 5,
              marginBottom: 5,
              fontSize: "1em",
              textAlign: "center",
              maxWidth: "280px",
            }}
          >
            {subHeader}
          </Text>
        )}
      </Stack>
      {showSearch && setSearchValue && (
        <Stack horizontalAlign="center">
          <SearchBox
            styles={{ root: { width: 200, marginBottom: 10 } }}
            underlined
            onChanged={(newValue) => {
              setSearchValue(newValue);
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};
