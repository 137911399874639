import App from "./App";
import "./i18nextConf";

// MSAL imports
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { FluentTheme, initializeIcons, ThemeProvider } from "@fluentui/react";
import { createRoot } from "react-dom/client";
import { msalConfig } from "./authConfig";

initializeIcons();

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      msalInstance.setActiveAccount(account);
    }
  });

  const container = document.getElementById("root");
  const root = createRoot(container!);

  root.render(
    <ThemeProvider theme={FluentTheme}>
      <App pca={msalInstance} />
    </ThemeProvider>
  );
});
