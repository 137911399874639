import { API_BASE_URL } from "src/constants";
import { get } from "src/core/utils/api";
import { Brand, Model } from "src/types/common";

const createBrandService = () => {
  const fetchBrandsByRegionAndMarket = async (
    accessToken: string,
    regionId: number,
    marketId: number,
    calculateIfHasSurveyResponse?: number,
    calculateCoverPercentage?: number
  ): Promise<Brand[]> => {
    const response = await get<Brand[]>(
      accessToken,
      API_BASE_URL +
        `/brands?regionId=${regionId}&marketId=${marketId}&calculateSurveyResponse=${
          calculateIfHasSurveyResponse ?? ""
        }&calculateCoverPercentage=${calculateCoverPercentage ?? ""}`
    );

    return response;
  };

  const fetchBrandModels = async (
    accessToken: string,
    brandCode: string,
    regionId: number,
    marketId: number,
    calculateIfHasSurveyResponse?: number
  ): Promise<Model[]> => {
    const response = await get<Model[]>(
      accessToken,
      API_BASE_URL +
        `/brands/${brandCode}/models?regionId=${regionId}&marketId=${marketId}&calculateSurveyResponse=${
          calculateIfHasSurveyResponse ?? ""
        }`
    );

    return response;
  };

  return {
    fetchBrandsByRegionAndMarket,
    fetchBrandModels,
  };
};
export const brandService = createBrandService();
