import { Stack } from "@fluentui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SurveySaveDialog } from "src/core/components/SurveyQuestions/components/SurveySaveDialog";
import { SurveyQuestions } from "src/core/components/SurveyQuestions/SurveyQuestions";
import { useAuth } from "src/core/hooks/useAuth";
import { surveyService } from "src/core/services/surveyService";
import { Survey, SurveyQuestion, SurveyQuestionOption } from "src/types/common";

export interface Props {
  survey: Survey;
  marketId?: number;
  brandCode?: string;
  modelNumber?: string;
  collection?: string;
  onSuccess: () => void;
}

export const OnePageSurvey = (props: Props) => {
  const { survey, marketId, brandCode, onSuccess, modelNumber, collection } =
    props;

  const [selectedOptions, setSelectedOptions] = useState<
    SurveyQuestionOption[]
  >([]);
  const [selectedQuestions, setSelectedQuestions] = useState<SurveyQuestion[]>(
    []
  );

  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const [errorOnSave, setErrorOnSave] = useState<boolean>(false);
  const [errorOnSaveMessage, setErrorOnSaveMessage] = useState<string>();

  const { t } = useTranslation();
  const { accessToken } = useAuth();

  const onSave = (
    selectedSurveyQuestions: SurveyQuestion[],
    selectedSurveyQuestionOptions: SurveyQuestionOption[]
  ) => {
    setSelectedQuestions(selectedSurveyQuestions);
    setSelectedOptions(selectedSurveyQuestionOptions);

    setErrorOnSave(false);
    setErrorOnSaveMessage(undefined);

    setShowConfirm(true);
  };

  const onSubmit = async () => {
    setErrorOnSave(false);

    const saveResult = await surveyService.saveSurveyResponse(
      accessToken,
      survey.id,
      selectedQuestions,
      selectedOptions,
      marketId,
      modelNumber,
      brandCode,
      collection
    );

    if (saveResult.success) {
      setShowConfirm(false);
      onSuccess();
    } else {
      setErrorOnSave(true);
      setErrorOnSaveMessage(saveResult.errorMessage);
    }
  };

  return (
    <Stack>
      <SurveySaveDialog
        showConfirm={showConfirm}
        hideConfirm={() => {
          setShowConfirm(false);
        }}
        onSubmit={onSubmit}
        error={errorOnSave}
        errorMessage={errorOnSaveMessage}
      />
      {survey && (
        <SurveyQuestions
          surveyQuestionGroups={survey.surveyQuestionGroups}
          onSave={onSave}
          submitButtonText={t("resources.40")}
        />
      )}
    </Stack>
  );
};
