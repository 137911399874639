import {
  IImageProps,
  Image as FluentImage,
  ImageLoadState,
  Stack,
  Text,
} from "@fluentui/react";
import { useState } from "react";
import noImage from "src/assets/common/no-image.jpg";

type Props = Omit<IImageProps, "type"> & { fallbackText?: string };

export const Image = (props: Props) => {
  const [isError, setIsError] = useState(false);

  return (
    <Stack verticalAlign="center" verticalFill>
      {isError &&
        (!!props.fallbackText ? (
          <Text
            style={{
              width: props.style?.width,
              textAlign: "center",
              fontWeight: 600,
              wordBreak: "break-word",
              fontSize: 14,
            }}
            as="b"
          >
            {props.fallbackText}
          </Text>
        ) : (
          <FluentImage {...props} src={noImage} />
        ))}
      {!isError && (
        <FluentImage
          {...props}
          src={props.src}
          onLoadingStateChange={(loadState: ImageLoadState) => {
            if (loadState === ImageLoadState.error) {
              setIsError(true);
            }
          }}
        />
      )}
    </Stack>
  );
};
