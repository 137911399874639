import { ImageFit, Stack, Text } from "@fluentui/react";
import {
  BoxCheckmarkSolidIcon,
  ChevronRightIcon,
} from "@fluentui/react-icons-mdl2";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Image } from "src/core/components/Image";
import { ModelImageModal } from "src/core/components/ModelImageModal";
import { Model } from "src/types/common";

interface Props {
  models: Model[];
  onClick: (model: Model) => void;
  onlyList?: boolean;
  filterValue?: string | undefined;
  imageIsClickable?: boolean;
  onlyReviews?: boolean;
}

export const ModelsGrid = (props: Props) => {
  const {
    onlyList,
    models,
    onClick,
    filterValue,
    imageIsClickable,
    onlyReviews,
  } = props;

  const [filteredModels, setFilteredModels] = useState<Model[]>(models);

  const [showImageModal, setShowImageModal] = useState(false);
  const [imageModalModel, setImageModalModel] = useState<Model>();

  const { t } = useTranslation();

  useEffect(() => {
    if (filterValue !== undefined && filterValue !== null) {
      if (filterValue === "") {
        setFilteredModels(models);
      } else {
        const filteredList = models.filter((model: Model) => {
          return (
            model.number.toLowerCase().includes(filterValue.toLowerCase()) ||
            model.gender.toLowerCase().includes(filterValue.toLowerCase()) ||
            model.fitting.toLowerCase().includes(filterValue.toLowerCase()) ||
            model.material.toLowerCase().includes(filterValue.toLowerCase()) ||
            model.type.toLowerCase().includes(filterValue.toLowerCase()) ||
            model.price
              ?.toString()
              .toLowerCase()
              .includes(filterValue.toLowerCase()) ||
            model.size
              ?.toString()
              .toLowerCase()
              .includes(filterValue.toLowerCase())
          );
        });

        setFilteredModels(filteredList);
      }
    }
  }, [filterValue, models]);

  return (
    <Stack horizontalAlign="center">
      <ModelImageModal
        model={imageModalModel}
        showImageModal={showImageModal}
        setShowImageModal={setShowImageModal}
      />
      <Stack
        horizontal
        wrap
        tokens={{ childrenGap: "5" }}
        horizontalAlign="start"
        style={{ maxWidth: onlyList ? 400 : undefined }}
      >
        {filteredModels.map((model: Model) => {
          return (
            <Stack
              grow
              horizontal
              verticalAlign="center"
              tokens={{ childrenGap: "10" }}
              style={{
                display: "flex",
                height: 105,
                width: 300,
              }}
              key={model.number}
            >
              <Stack verticalAlign={"baseline"} verticalFill>
                <Image
                  style={{
                    height: 80,
                    width: 100,
                    top: 0,
                    cursor: imageIsClickable ? "pointer" : undefined,
                  }}
                  imageFit={ImageFit.contain}
                  src={model.imageUrl}
                  onClick={() => {
                    if (imageIsClickable) {
                      setImageModalModel(model);
                      setShowImageModal(true);
                    }
                  }}
                />
              </Stack>

              <Stack
                verticalAlign={"baseline"}
                tokens={{ childrenGap: "2" }}
                verticalFill
                grow
                onClick={() => {
                  onClick(model);
                }}
              >
                <b>{model.number}</b>
                <span>
                  {model.type} | {model.gender}
                </span>
                <span>
                  {model.material} | {model.size}
                </span>
                <span>
                  {model?.price && model.currency
                    ? `${model?.price} ${model?.currency} | `
                    : ""}
                  {model.fitting}
                </span>
              </Stack>

              <Stack
                horizontal
                verticalAlign="center"
                verticalFill
                onClick={() => {
                  onClick(model);
                }}
              >
                {model.hasResponse && (
                  <BoxCheckmarkSolidIcon
                    style={{
                      marginBottom: 20,
                      width: 20,
                      height: 20,
                      marginRight: 15,
                      color: "green",
                    }}
                  />
                )}
                <ChevronRightIcon
                  style={{
                    cursor:
                      onlyReviews && !model.hasResponse
                        ? "not-allowed"
                        : "pointer",
                    left: 10,
                    marginBottom: 20,
                    marginRight: 10,
                    height: 30,
                    width: 30,
                    color:
                      onlyReviews && !model.hasResponse
                        ? "lightgrey"
                        : undefined,
                  }}
                />
              </Stack>
            </Stack>
          );
        })}
        {filteredModels.length === 0 && (
          <Text as="h3">{t(`messages.noModels`)}</Text>
        )}
      </Stack>
    </Stack>
  );
};
