import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import translationDE from "src/languages/translation-DE.json";
import translationEN from "src/languages/translation-EN.json";
import translationES from "src/languages/translation-ES.json";
import translationFR from "src/languages/translation-FR.json";
import translationID from "src/languages/translation-ID.json";
import translationIT from "src/languages/translation-IT.json";
import translationJA from "src/languages/translation-JA.json";
import translationKO from "src/languages/translation-KO.json";
import translationMY from "src/languages/translation-MY.json";
import translationPT from "src/languages/translation-PT.json";
import translationTH from "src/languages/translation-TH.json";
import translationZH from "src/languages/translation-ZH.json";

const fallbackLng = ["en"];
const availableLanguages = [
  "en",
  "zh",
  "fr",
  "id",
  "it",
  "ja",
  "ko",
  "th",
  "my",
  "pt",
  "es",
  "de",
];

const resources = {
  en: {
    translation: translationEN,
  },
  zh: {
    translation: translationZH,
  },
  fr: {
    translation: translationFR,
  },
  id: {
    translation: translationID,
  },
  it: {
    translation: translationIT,
  },
  ja: {
    translation: translationJA,
  },
  ko: {
    translation: translationKO,
  },
  th: {
    translation: translationTH,
  },
  my: {
    translation: translationMY,
  },
  pt: {
    translation: translationPT,
  },
  de: {
    translation: translationDE,
  },
  es: {
    translation: translationES,
  },
};

i18n
  .use(Backend) // load translations using http (default public/assets/locals/en/translations)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    resources,
    fallbackLng, // fallback language is english.

    detection: {
      checkWhitelist: true, // options for language detection
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
  });

export default i18n;
