import { atom } from "recoil";
import { Brand, Market, Model, Region, User } from "src/types/common";

type MainAppState = {
  selectedRegion?: Region;
  selectedMarket?: Market;
  selectedBrand?: Brand;
  selectedModel?: Model;
};

type RegionMarketsState = {
  regionId: number;
  markets: Market[];
};

export const mainAppState = atom<MainAppState>({
  key: "mainAppState",
  default: {
    selectedRegion: undefined,
    selectedMarket: undefined,
    selectedBrand: undefined,
    selectedModel: undefined,
  },
});

export const regionsState = atom<Region[]>({
  key: "regionsState",
  default: [],
});

export const marketsState = atom<RegionMarketsState>({
  key: "marketsState",
  default: undefined,
});

export const currentUserState = atom<User>({
  key: "userState",
  default: undefined,
});
