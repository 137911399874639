import { appConstants } from "src/appConstants";
import { API_BASE_URL } from "src/constants";
import { get, post } from "src/core/utils/api";
import {
  SaveSurveyResponse,
  SaveSurveyResult,
  Survey,
  SurveyQuestion,
  SurveyQuestionOption,
  SurveyResponseAnswer,
} from "src/types/common";

const createSurveyService = () => {
  const fetchSurvey = async (
    accessToken: string,
    surveyId: number,
    language?: string
  ): Promise<Survey> => {
    const response = await get<Survey>(
      accessToken,
      API_BASE_URL + `/surveys/${surveyId}?language=${language ?? ""}`
    );

    return response;
  };

  const getOverallSurvey = async (
    accessToken: string,
    language?: string
  ): Promise<Survey> => {
    const response = await get<Survey>(
      accessToken,
      API_BASE_URL +
        `/surveys/${appConstants()?.overallSurveyId}?language=${language ?? ""}`
    );

    return response;
  };

  const getModelSurvey = async (
    accessToken: string,
    modelNumber: string,
    language?: string
  ): Promise<Survey> => {
    const response = await get<Survey>(
      accessToken,
      API_BASE_URL +
        `/surveys/${
          appConstants()?.modelSurveyId
        }?modelNumber=${modelNumber}&language=${language ?? ""}`
    );

    return response;
  };

  const saveSurveyResponse = async (
    accessToken: string,
    surveyId: number,
    questions: SurveyQuestion[],
    options: SurveyQuestionOption[],
    marketId?: number,
    modelNumber?: string,
    brandCode?: string,
    collection?: string
  ): Promise<SaveSurveyResult> => {
    try {
      let data: SaveSurveyResponse = {
        surveyId: surveyId,
        marketId: marketId,
        brandCode: brandCode,
        modelNumber: modelNumber,
        collection: collection,
        surveyResponseAnswers: [],
      };

      questions.forEach((q: SurveyQuestion) => {
        let objectToAdd: SurveyResponseAnswer = {
          questionId: q.id,
          customValue: q.value,
        };

        if (q.attachments && q.attachments.length > 0) {
          objectToAdd.attachments = [];

          q.attachments.forEach((img: any) => {
            objectToAdd.attachments?.push({
              data: img.dataURL,
              name: img?.file?.name,
              type: img?.file?.type,
              size: img?.file?.size,
            });
          });
        }

        data.surveyResponseAnswers?.push(objectToAdd);
      });

      options.forEach((opt: SurveyQuestionOption) => {
        data.surveyResponseAnswers?.push({
          questionId: opt.surveyQuestionId,
          questionOptionId: opt.id,
          value: opt.value,
          customValue: opt.customValue,
        });
      });

      await post<any>(accessToken, API_BASE_URL + `/surveys`, data);

      return { success: true };
    } catch (error: any) {
      let errorMessage: string | undefined;

      if (error?.message) {
        errorMessage = error?.message.replace("An error occured: ", "");
      }

      return { success: false, errorMessage: errorMessage };
    }
  };

  return {
    fetchSurvey,
    getOverallSurvey,
    getModelSurvey,
    saveSurveyResponse,
  };
};

export const surveyService = createSurveyService();
