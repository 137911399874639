import { Image, Stack, Text } from "@fluentui/react";
import { getImageAttachmentUrl } from "src/core/utils/attachment";
import {
  AttachmentsIdentifier,
  SurveyQuestion,
  SurveyQuestionGroup,
  SurveyResponse,
  SurveyResponseAnswer,
} from "src/types/common";

export interface Props {
  surveyResponse: SurveyResponse;
}

export const SurveyResponseRender = (props: Props) => {
  const { surveyResponse } = props;

  return (
    <Stack style={{ paddingLeft: 15, paddingRight: 15 }}>
      {surveyResponse?.surveyQuestionGroups?.map(
        (group: SurveyQuestionGroup, index) => {
          return (
            <Stack key={`s-re-g-${index}`}>
              {group.displayTitle && (
                <Text
                  as="h2"
                  variant="mediumPlus"
                  style={{
                    fontWeight: 600,
                    textDecoration: "underline",
                    marginBottom: 0,
                  }}
                >
                  {group.displayTitle}
                </Text>
              )}
              {group.surveyQuestions.map((question: SurveyQuestion, index) => {
                return (
                  <Stack key={`s-re-q-${index}`}>
                    <Text
                      variant={"medium"}
                      as="h3"
                      style={{ fontWeight: 600 }}
                    >
                      {question.title}
                    </Text>
                    {(question.surveyResponseAnswers || []).length > 0 ? (
                      question.surveyResponseAnswers?.map(
                        (answer: SurveyResponseAnswer, index) => {
                          return (
                            <Stack key={`ans-${index}`}>
                              <Text
                                key={`s-re-q-a-${index}`}
                                as="p"
                                style={{ marginBottom: 5 }}
                              >
                                {answer.customValue ?? answer.value}
                              </Text>
                              {answer.attachmentsIdentifiers &&
                                answer.attachmentsIdentifiers.length > 0 && (
                                  <Stack>
                                    {answer.attachmentsIdentifiers.map(
                                      (att: AttachmentsIdentifier, index) => {
                                        return (
                                          <Image
                                            key={`img-${index}-${att.identifier}`}
                                            style={{ maxWidth: 320 }}
                                            src={getImageAttachmentUrl(
                                              att.identifier
                                            )}
                                          />
                                        );
                                      }
                                    )}
                                  </Stack>
                                )}
                            </Stack>
                          );
                        }
                      )
                    ) : (
                      <Text as="p" style={{ marginBottom: 5 }}>
                        {question.value}
                      </Text>
                    )}
                  </Stack>
                );
              })}
            </Stack>
          );
        }
      )}
    </Stack>
  );
};
