import { ImageFit, Stack, Text } from "@fluentui/react";
import { BoxCheckmarkSolidIcon } from "@fluentui/react-icons-mdl2";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Image } from "src/core/components/Image";
import { Brand } from "src/types/common";

interface Props {
  brands: Brand[];
  onClick: (brand: Brand) => void;
  filterValue?: string | undefined;
  onlyReviews?: boolean;
}

export const BrandsGrid = (props: Props) => {
  const { brands, onClick, filterValue, onlyReviews } = props;

  const [filteredBrands, setFilteredBrands] = useState<Brand[]>(brands);

  const { t } = useTranslation();

  useEffect(() => {
    if (filterValue !== undefined && filterValue !== null) {
      if (filterValue === "") {
        setFilteredBrands(brands);
      } else {
        const filteredList = brands.filter((brand: Brand) => {
          return (
            brand.name.toLowerCase().includes(filterValue.toLowerCase()) ||
            brand.code.toLowerCase().includes(filterValue.toLowerCase())
          );
        });

        setFilteredBrands(filteredList);
      }
    }
  }, [brands, filterValue]);

  return (
    <Stack horizontalAlign="center" style={{ maxWidth: 800 }}>
      <Stack
        horizontalAlign="center"
        horizontal
        wrap
        tokens={{ childrenGap: "20" }}
        style={{ minWidth: 120 }}
      >
        {filteredBrands.map((brand: Brand, index) => {
          return (
            <Stack
              horizontalAlign="center"
              style={{
                display: "flex",
                height: 140,
                cursor:
                  onlyReviews && !brand.hasResponse ? "not-allowed" : "pointer",
                position: "relative",
                border:
                  onlyReviews && !brand.hasResponse
                    ? "1px lightgrey solid"
                    : "1px solid",
                margin: 0,
                padding: 10,
                width: 120,
              }}
              key={`brand-${brand.id}-${index}`}
              onClick={() => {
                onClick(brand);
              }}
            >
              {brand.hasResponse ? (
                brand.hasResponse && (
                  <BoxCheckmarkSolidIcon
                    style={{
                      position: "absolute",
                      top: 5,
                      right: 5,
                      width: 15,
                      height: 15,
                      zIndex: 99,
                      color: "green",
                    }}
                  />
                )
              ) : brand.coverPercentage !== undefined &&
                brand.coverPercentage !== null ? (
                <Text
                  variant="mediumPlus"
                  style={{
                    position: "absolute",
                    top: 5,
                    right: 5,
                    zIndex: 99,
                    color: "#179317",
                    fontWeight: 600,
                  }}
                >
                  {brand.coverPercentage}%
                </Text>
              ) : (
                ""
              )}
              <Image
                style={{ height: 100, width: 100 }}
                imageFit={ImageFit.contain}
                src={brand.logoUrl}
                fallbackText={brand.name}
              />
              <Text
                as="span"
                style={{
                  paddingTop: 3,
                  fontSize: "12px",
                }}
                nowrap={true}
                block={true}
              >
                {brand.title}
              </Text>
            </Stack>
          );
        })}
        {filteredBrands.length === 0 && (
          <Text as="h3">{t(`messages.noBrands`)}</Text>
        )}
      </Stack>
    </Stack>
  );
};
