import {
  ChoiceGroup,
  IChoiceGroupOption,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SurveyQuestion, SurveyQuestionOption } from "src/types/common";

export interface Props {
  surveyQuestion: SurveyQuestion;
  onChange: (
    add: boolean,
    question: SurveyQuestion,
    option: SurveyQuestionOption,
    removeAllExistingQuestionOptions: boolean
  ) => void;
  preselectedSurveyQuestionOption?: SurveyQuestionOption;
}

export const CheckBoxRadio = (props: Props) => {
  const [selectedKey, setSelectedKey] = useState<number | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  const [selectedOption, setSelectedOption] = useState<SurveyQuestionOption>();

  const [hasFreeText, setHasFreeText] = useState(false);
  const [commentDisabled, setCommentDisabled] = useState(true);
  const [comment, setComment] = useState("");

  const { t } = useTranslation();

  const { surveyQuestion, onChange, preselectedSurveyQuestionOption } = props;

  let optionsWithLabels: IChoiceGroupOption[] = [];

  if (
    surveyQuestion.surveyQuestionOptions &&
    surveyQuestion.surveyQuestionOptions.length > 0
  ) {
    surveyQuestion.surveyQuestionOptions.forEach(
      (option: SurveyQuestionOption) => {
        optionsWithLabels.push({
          key: option.id,
          text:
            option.displayTitle && option.displayTitle?.trim() !== ""
              ? option.displayTitle
              : option.displayValue,
        });
      }
    );
  }

  useEffect(() => {
    if (selectedOption) {
      if (selectedOption.type === "FreeText") {
        setCommentDisabled(false);
      } else {
        setComment("");
        setCommentDisabled(true);
      }
    }
  }, [selectedOption]);

  useEffect(() => {
    if (
      surveyQuestion.surveyQuestionOptions.some((e) => e.type === "FreeText")
    ) {
      setHasFreeText(true);
    }
  }, [surveyQuestion.surveyQuestionOptions]);

  useEffect(() => {
    if (preselectedSurveyQuestionOption) {
      setSelectedKey(parseInt(preselectedSurveyQuestionOption.id));

      if (preselectedSurveyQuestionOption.type === "FreeText") {
        setComment(preselectedSurveyQuestionOption?.customValue || "");
      }

      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [preselectedSurveyQuestionOption]);

  return (
    <Stack
      tokens={{ childrenGap: "5", maxWidth: 400 }}
      verticalAlign="baseline"
    >
      <Text
        variant={"medium"}
        style={{ fontWeight: 500, textAlign: "justify" }}
      >
        {surveyQuestion.displayTitle}
      </Text>
      {!loading && (
        <ChoiceGroup
          styles={{
            root: { marginTop: 0 },
            flexContainer: {
              width: "100%",
              marginLeft: 5,
            },
          }}
          defaultSelectedKey={selectedKey}
          options={optionsWithLabels}
          onChange={(
            _e?: React.FormEvent<HTMLElement | HTMLInputElement>,
            option?: IChoiceGroupOption
          ) => {
            if (option?.key) {
              const selectedOptionFiltered =
                surveyQuestion.surveyQuestionOptions.find(
                  (item: SurveyQuestionOption) => {
                    return option?.key === item?.id;
                  }
                );

              if (selectedOptionFiltered) {
                onChange(true, surveyQuestion, selectedOptionFiltered, true);
                setSelectedOption(selectedOptionFiltered);
              }
            }
          }}
        />
      )}

      {hasFreeText && (
        <Stack style={{ marginTop: 15 }}>
          <TextField
            disabled={commentDisabled}
            placeholder={t("resources.17")}
            value={comment}
            multiline
            rows={3}
            styles={{ root: { width: "100%" } }}
            onChange={(
              event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
              newValue?: string
            ) => {
              setComment(newValue ?? "");

              let freeTextOption = surveyQuestion.surveyQuestionOptions.find(
                (item: SurveyQuestionOption) => {
                  return item.type === "FreeText";
                }
              );

              if (freeTextOption) {
                const optionWithComment = {
                  ...freeTextOption,
                  customValue: newValue || "",
                };

                onChange(true, surveyQuestion, optionWithComment, true);
              }
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};
